import React from "react"
import { formatDatetimeForInput } from "../../../utils/time"

interface EditAllocationNextReplenishmentProps {
  handleChangeNextReplenishment: React.ChangeEventHandler<HTMLInputElement>
  nextReplenishment: Date
}

const EditAllocationNextReplenishment: React.FC<EditAllocationNextReplenishmentProps> = ({
  handleChangeNextReplenishment,
  nextReplenishment,
}) => (
  <div className="items-start bg-grey-5 p-3 flex flex-col border border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
    <label htmlFor="next-replenishment">Next replenishment:</label>
    <input
      type="datetime-local"
      id="next-replenishment"
      name="next-replenishment"
      value={formatDatetimeForInput(nextReplenishment.toString())}
      onChange={handleChangeNextReplenishment}
    />
  </div>
)

export default EditAllocationNextReplenishment
