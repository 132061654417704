import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import FormToasterContainer from "../../components/molecules/form-toaster"
import toast from "react-hot-toast"
import Toaster from "../../components/declarative-toaster"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import { checkForDirtyState } from "../../utils/form-helpers"
import { handleFormError } from "../../utils/handle-form-error"
import KitListForm from "./kit-list-form"
import {
  KitListFormProvider,
  useKitListForm,
} from "./kit-list-form/form/kitList-form-context"
import { FieldValues } from "react-hook-form"
import { useAdminCreateKitList } from "@iq/medusa-react"
import { formValuesToCreateKitListMapper } from "./kit-list-form/form/mappers"

const TOAST_ID = "new-kit-list-dirty"

const CreateKitListPage = () => {
  const notification = useNotification()
  const createKitList = useAdminCreateKitList()
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (data) => {
    setIsLoading(true)

    createKitList.mutate(formValuesToCreateKitListMapper(data), {
      onSuccess: ({ kit_list }) => {
        setIsLoading(false)
        notification("Success", "kit list was succesfully created", "success")
        navigate(`/a/kit-lists/${kit_list.id}`)
      },
      onError: (error) => {
        setIsLoading(false)
        notification("Error creating kit list", error.toString(), "error")
        return
      },
    })
  }
  return (
    <KitListFormProvider onSubmit={onSubmit}>
      <KitListForm />
      <SaveNotification isLoading={isLoading} />
    </KitListFormProvider>
  )
}

const SaveNotification = ({ isLoading = false }) => {
  const { formState, onSubmit, handleSubmit, resetForm } = useKitListForm()
  const [visible, setVisible] = useState(false)

  const onPublish = (values: FieldValues) => {
    onSubmit({ ...values, status: "published" })
    resetForm()
  }

  const onSaveDraft = (values: FieldValues) => {
    onSubmit({ ...values, status: "draft" })
    resetForm()
  }

  const isDirty = checkForDirtyState(formState.dirtyFields, {})

  useEffect(() => {
    if (isDirty) {
      setVisible(true)
    } else {
      setVisible(false)
    }

    return () => {
      toast.dismiss(TOAST_ID)
    }
  }, [isDirty])

  return (
    <Toaster
      visible={visible}
      duration={Infinity}
      id={TOAST_ID}
      position="bottom-right"
    >
      <FormToasterContainer isLoading={isLoading}>
        <FormToasterContainer.Actions>
          <FormToasterContainer.MultiActionButton
            actions={[
              {
                label: "Save and publish",
                onClick: handleSubmit(onPublish, handleFormError),
              },
              {
                label: "Save as draft",
                onClick: handleSubmit(onSaveDraft, handleFormError),
              },
            ]}
          >
            Save
          </FormToasterContainer.MultiActionButton>
          <FormToasterContainer.DiscardButton onClick={resetForm}>
            Discard
          </FormToasterContainer.DiscardButton>
        </FormToasterContainer.Actions>
      </FormToasterContainer>
    </Toaster>
  )
}

export default CreateKitListPage
