import { useLocation } from "@reach/router"
import { useAdminKitLists } from "@iq/medusa-react"
import React, { useState } from "react"
import ProductsFilter from "../../../domain/products/filter-dropdown"
import { useKitListFilters } from "./use-kit-list-filters"
import useKitListTableColumn from "./use-kit-list-column"
import EntityTable from "../entity-table"
import useKitListActions from "./use-kit-list-actions"

const DEFAULT_PAGE_SIZE = 15
const DEFAULT_PAGE_SIZE_TILE_VIEW = 18

type KitListTableProps = {}

const defaultQueryProps = {
  expand: "items,customers",
}

const KitListTable: React.FC<KitListTableProps> = () => {
  const location = useLocation()

  const {
    removeTab,
    setTab,
    saveTab,
    availableTabs: filterTabs,
    activeFilterTab,
    reset,
    setFilters,
    setLimit,
    filters,
    queryObject,
  } = useKitListFilters(location.search, defaultQueryProps)

  const [query, setQuery] = useState(queryObject.query)

  const clearFilters = () => {
    reset()
    setQuery("")
  }

  const setListView = () => {
    setLimit(DEFAULT_PAGE_SIZE)
    setShowList(true)
  }

  const setTileView = () => {
    setLimit(DEFAULT_PAGE_SIZE_TILE_VIEW)
    setShowList(false)
  }

  const [showList, setShowList] = React.useState(true)

  const [offset, setOffset] = useState<number>(0)

  const { kit_lists, isLoading, isRefetching, count, refetch } =
    useAdminKitLists({
      ...queryObject,
      offset,
      q: query,
    })

  const [columns] = useKitListTableColumn({
    setTileView,
    setListView,
    showList,
  })

  return (
    <EntityTable
      defaultQueryProps={defaultQueryProps}
      useEntityFilters={useKitListFilters}
      useEntityActions={useKitListActions}
      entities={kit_lists}
      isLoading={isLoading}
      isRefetching={isRefetching}
      count={count}
      name="kit-lists"
      showList={showList}
      columns={columns}
      query={query}
      setQuery={setQuery}
      setOffset={setOffset}
      refetch={refetch}
    >
      {{
        filters: (
          <ProductsFilter
            filters={filters}
            submitFilters={setFilters}
            clearFilters={clearFilters}
            tabs={filterTabs}
            onTabClick={setTab}
            activeTab={activeFilterTab}
            onRemoveTab={removeTab}
            onSaveTab={saveTab}
          />
        ),
      }}
    </EntityTable>
  )
}
export default KitListTable
