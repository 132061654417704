import React from "react"
import { formatAmountWithSymbol } from "../../../../utils/prices"
import StatusDot from "../../../../components/fundamentals/status-indicator"

const OrderLine = ({ item, currencyCode }) => {
  return (
    <div className="flex justify-between mb-1 py-2 mx-[-5px] px-[5px] hover:bg-grey-5 rounded-rounded">
      <div className="flex space-x-4 justify-center items-center">
        <div className="flex flex-col">
          <StatusDot className="mb-1" title={item.status} variant="default" />
          <div className="flex h-[48px] w-[36px]">
            <img
              src={item.thumbnail}
              className="rounded-rounded object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <span className="inter-small-regular text-grey-90 max-w-[225px] truncate">
            {item.title}
          </span>
          {item?.variant && (
            <>
              <span className="inter-small-regular text-grey-50">
                Variant SKU: {item.variant.sku}
              </span>
              <span className="inter-small-regular text-grey-50">
                Supplier SKU: {item.variant.supplierSKU}
              </span>
              <span className="inter-small-regular">
                Cost:{" "}
                {formatAmountWithSymbol({
                  amount: item.variant.costPrice,
                  currency: currencyCode,
                  digits: 2,
                })}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center text-center">
        <div className="inter-small-regular min-w-[150px]">{item.quantity}</div>
        <div className="inter-small-regular min-w-[150px]">
          {formatAmountWithSymbol({
            amount: item.unit_price,
            currency: currencyCode,
            digits: 2,
          })}
        </div>
        <div className="inter-small-regular min-w-[150px]">
          {formatAmountWithSymbol({
            amount: item.unit_price * item.quantity,
            currency: currencyCode,
            digits: 2,
          })}
        </div>
      </div>
      {/* <div className="border rounded-rounded px-1">
          <div className="text-center text-grey-50">Cost</div>
          <div className="flex items-center">
            <div className="flex small:space-x-2 medium:space-x-4 large:space-x-6 mr-3">
              <div className="inter-small-regular text-grey-50">
                {formatAmountWithSymbol({
                  amount: item.variant.costPrice,
                  currency: currencyCode,
                  digits: 2,
                })}
              </div>
              <div className="inter-small-regular text-grey-50">
                x {item.quantity}
              </div>
              <div className="inter-small-regular text-grey-90">
                {formatAmountWithSymbol({
                  amount: item.variant.costPrice * item.quantity,
                  currency: currencyCode,
                  digits: 2,
                })}
              </div>
            </div>
            <div className="inter-small-regular text-grey-50">
              {currencyCode.toUpperCase()}
            </div>
          </div>
        </div> */}
    </div>
  )
}

export default OrderLine
