import {
  useAdminCreateVariant,
  useAdminDeleteVariant,
  useAdminUpdateVariant,
} from "@iq/medusa-react"
import React, { useState } from "react"
import VariantEditor from "../../domain/products/details/variants/variant-editor"
import { buildOptionsMap } from "../../domain/products/product-form/utils"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import ChildGrid from "../base/child-grid"
import DuplicateIcon from "../fundamentals/icons/duplicate-icon"
import EditIcon from "../fundamentals/icons/edit-icon"
import TrashIcon from "../fundamentals/icons/trash-icon"
import { useGridColumns } from "./use-grid-columns"

const VariantGrid = ({ product, variants, edit, onVariantsChange }) => {
  const createVariant = useAdminCreateVariant(product?.id)
  const updateVariant = useAdminUpdateVariant(product?.id)
  const deleteVariant = useAdminDeleteVariant(product?.id)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState<any | null>(null)

  const notification = useNotification()
  const dialog = useImperativeDialog()

  const columns = useGridColumns(product, edit)

  const handleChange = (index, field, value) => {
    const newEntities = [...variants]
    newEntities[index] = {
      ...newEntities[index],
      [field]: value,
    }

    onVariantsChange(newEntities)
  }

  const handleUpdateVariant = (data) => {
    updateVariant.mutate(
      { variant_id: selectedVariant?.id, ...data },
      {
        onSuccess: () => {
          notification("Success", `Successfully update Variant`, "success")
          setSelectedVariant(null)
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
  }

  const handleDeleteVariant = async (variant) => {
    const shouldDelete = await dialog({
      heading: `Delete product Variant`,
      text: "Are you sure?",
    })

    if (shouldDelete) {
      return deleteVariant.mutate(variant.id)
    }
  }

  const handleDuplicateVariant = async (entity) => {
    createVariant.mutate(
      { ...entity },
      {
        onSuccess: () => {
          notification("Success", `Successfully created Variant`, "success")
          setSelectedVariant(null)
        },
        onError: (err) => {
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
  }

  const editVariantActions = (entity) => {
    return [
      {
        label: "Edit",
        icon: <EditIcon size={20} />,
        onClick: () => setSelectedVariant(entity),
      },
      {
        label: "Duplicate",
        icon: <DuplicateIcon size={20} />,
        onClick: () => {
          setSelectedVariant(entity)
          setIsDuplicate(true)
        },
      },
      {
        label: "Delete",
        icon: <TrashIcon size={20} />,
        onClick: () => handleDeleteVariant(entity),
        variant: "danger",
      },
    ]
  }
  return (
    <>
      <ChildGrid
        entities={variants}
        edit={edit}
        columns={columns}
        editEntityActions={editVariantActions}
        handleChange={handleChange}
      >
        {selectedVariant && (
          <VariantEditor
            variant={selectedVariant}
            onCancel={() => setSelectedVariant(null)}
            onSubmit={
              isDuplicate ? handleDuplicateVariant : handleUpdateVariant
            }
            optionsMap={buildOptionsMap(product, selectedVariant)}
            title="Edit variant"
          />
        )}
      </ChildGrid>
    </>
  )
}

export default VariantGrid
