import React, { useMemo } from "react"
import moment from "moment"

export const useCustomerColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Date added",
        accessor: "created_at", // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => moment(value).format("DD MMM YYYY"),
      },
      {
        Header: "Name",
        accessor: "customer",
        Cell: ({ row, cell }) => (
          <div className="text-left">{row?.original?.name}</div>
        ),
      },
      {
        Header: "Customers",
        accessor: "Customers",
        Cell: ({ row, cell }) => (
          <div className="text-left">{row?.original?.customers}</div>
        ),
      },
    ],
    []
  )

  return [columns]
}
