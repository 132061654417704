import React from "react"
import clsx from "clsx"
import { formatAmountWithSymbol } from "../../../../utils/prices"

export const DisplayTotal = ({
  totalAmount,
  totalTitle,
  currency,
  variant = "regular",
  subtitle = "",
  totalColor = "text-grey-90 basis-1/2",
}) => (
  <div className="flex mt-4 items-center text-right">
    <div
      className={clsx("text-grey-90 basis-1/2", {
        "inter-small-regular": variant === "regular",
        "inter-small-semibold": variant === "large" || variant === "bold",
      })}
    >
      {totalTitle}
    </div>
    {subtitle && (
      <div className="inter-small-regular text-grey-50 mt-1">{subtitle}</div>
    )}
    <div
      className={clsx(totalColor, {
        "inter-small-regular": variant === "regular",
        "inter-large-semibold": variant === "bold",
        "inter-xlarge-semibold": variant === "large",
      })}
    >
      {formatAmountWithSymbol({
        amount: totalAmount,
        currency,
      })}
    </div>
  </div>
)
