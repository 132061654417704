import { useAdminVariants } from "@iq/medusa-react"
import React, { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../../components/fundamentals/button"
import Input from "../../../../../components/molecules/input"
import Modal from "../../../../../components/molecules/modal"
// import Select from "../../../../../components/molecules/select"
import Select from "react-select/async"
import { medusaClient } from "../../../../../medusa-client"
import { convertEmptyStringToNull } from "../../../../../utils/convert-empty-string-to-null"
import { ProductVariant } from "@medusajs/medusa"
import { components } from "react-select"
import { GroupHeadingProps } from "react-select/dist/declarations/src/components/Group"
import XCircleIcon from "../../../../../components/fundamentals/icons/x-circle-icon"

const primaryColor = "#7c3aed"

const lighten = (amount, color) => {
  // Convert hex to RGB values
  const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16))
  // Apply lighten algorithm
  const lightenChannel = (channel) =>
    Math.round(channel + (255 - channel) * amount)
  const [lightenedR, lightenedG, lightenedB] = [r, g, b].map(lightenChannel)
  // Convert RGB back to hex
  const lightenColor = `#${((lightenedR << 16) | (lightenedG << 8) | lightenedB)
    .toString(16)
    .padStart(6, "0")}`
  return lightenColor
}

const defaultItem = {
  variants: [] as any[],
  quantity: 0,
}

type BaseGroupHeadingProps = React.PropsWithChildren<
  GroupHeadingProps<
    {
      label: string
      value: string
    },
    true,
    any
  >
>
const GroupHeading = ({
  children,
  selectedOptions,
  setSelectedOptions,
  isExpanded,
  setIsExpanded,
  ...props
}: BaseGroupHeadingProps & {
  selectedOptions: Array<{ label: string; value: string }>
  setSelectedOptions: (value: any) => void
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
}) => {
  const { hasSelectedAll } = useMemo(() => {
    const o1 = props.data.options
    const o2 = selectedOptions

    let hasSelectedAll = false
    let selectCount = 0
    for (let i = 0; i < o1.length; i++) {
      if (o2.findIndex((x) => x.label === o1[i].label) === -1) {
        hasSelectedAll = false
        break
      }
      hasSelectedAll = true
      selectCount++
    }

    return {
      hasSelectedAll,
      selectCount,
    }
  }, [])

  const onClickHandler = () => {
    if (!hasSelectedAll) {
      setSelectedOptions([...selectedOptions, ...props.data.options])
    } else {
      setSelectedOptions(
        selectedOptions.filter((x) => {
          return props.data.options.findIndex((y) => y.label === x.label) === -1
        })
      )
    }
  }

  return (
    <components.GroupHeading
      {...props}
      className={`${props.className}`}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 4,
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <input
        type="checkbox"
        className="h-4 w-4 accent-purple-600"
        checked={hasSelectedAll}
        onChange={onClickHandler}
      />
      <button onClick={onClickHandler}>
        <span className="text-base">{children}</span>
      </button>

      <button
        className="border rounded px-2"
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <span className="text-xs">
          {isExpanded ? "Hide Variants" : "Show Variants"}
        </span>
      </button>
    </components.GroupHeading>
  )
}

function SelectGroup(props: {
  placeholder: string
  name: string
  label: string
  selectedOptions: Array<{ label: string; value: string }>
  setSelectedOptions: (value: any) => void
  options: Array<{ label: string; value: string }>
  loadOptions: (inputValue: string) => Promise<any[]>
}) {
  const [showSelected, setShowSelected] = React.useState(true)
  const [expandedGroups, setExpandedGroups] = React.useState<string[]>([])
  return (
    <div className="space-y-4 pt-4">
      <span className="inter-base-semibold">{props.label}</span>
      <div>
        <Select
          isMulti
          defaultOptions={true}
          value={props.selectedOptions}
          name={props.name}
          controlShouldRenderValue={false}
          isClearable
          hideSelectedOptions={!showSelected}
          placeholder={props.placeholder || "Select..."}
          closeMenuOnSelect={false}
          styles={{
            option: (provided, state) => ({
              ...provided,
            }),
          }}
          options={props.options}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            // Option: () => null,
            Group: ({ children, ...p }) => {
              return (
                <components.Group
                  {...p}
                  className="p-0"
                  Heading={(h) => (
                    <GroupHeading
                      selectedOptions={props.selectedOptions}
                      setSelectedOptions={props.setSelectedOptions}
                      isExpanded={expandedGroups.includes(p.data.label)}
                      setIsExpanded={(value) => {
                        if (value) {
                          setExpandedGroups([
                            ...expandedGroups,
                            p.data.label as string,
                          ])
                        } else {
                          setExpandedGroups(
                            expandedGroups.filter((x) => x !== p.data.label)
                          )
                        }
                      }}
                      {...h}
                    />
                  )}
                >
                  {expandedGroups.includes(p.data.label) ? (
                    <div className="bg-gray-100">{children}</div>
                  ) : (
                    <></>
                  )}
                </components.Group>
              )
            },
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: primaryColor,
              primary25: lighten(0.8, primaryColor),
              primary50: lighten(0.65, primaryColor),
              primary75: lighten(0.55, primaryColor),
            },
          })}
          menuShouldBlockScroll
          // menuPortalTarget={document.body}
          // styles={{
          //   menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          //   valueContainer: (provided) => ({
          //     ...provided,
          //   }),
          // }}
          menuPlacement="auto"
          maxMenuHeight={300}
          loadOptions={props.loadOptions}
          onChange={(value) => {
            props.setSelectedOptions(value as any)
          }}
        />

        <div className="flex space-x-2 pb-2">
          <button onClick={() => setShowSelected(!showSelected)}>
            <span className="text-xs text-gray-600">
              {showSelected ? "Hide selected" : "Show selected"}
            </span>
          </button>
        </div>
      </div>
      <div
        className="flex flex-wrap gap-2 bg-gray-100 p-2 rounded"
        style={{
          minHeight: 60,
          maxHeight: 240,
          overflowY: "auto",
        }}
      >
        {props.selectedOptions?.map((c) => {
          return (
            <div className="flex items-center justify-between border p-2 rounded-lg space-x-2 bg-white text-xs">
              <span className="">{c.label}</span>
              <button
                onClick={() => {
                  props.setSelectedOptions(
                    props.selectedOptions.filter((s) => s.value !== c.value)
                  )
                }}
              >
                <XCircleIcon color="#9CA3AF" className="mr-2" />
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function convertVariantsToGroupedOptions(variants: ProductVariant[]) {
  const groupedByTitle = variants.reduce((acc, curr) => {
    const key = curr.product!.title

    const value = {
      label: curr.title,
      value: curr,
    }

    if (Array.isArray(acc[key])) {
      acc[key].push(value)
    } else {
      acc[key] = [value]
    }

    return acc
  }, {})

  console.log("Grouped by title:", groupedByTitle)

  const groupedOptions = Object.entries(groupedByTitle)
    .map(([key, value]) => {
      return {
        label: key,
        options: value,
      }
    })
    .flat()

  return groupedOptions
}

const ItemEditor = ({ item = defaultItem, onSubmit, onCancel, title }) => {
  const {
    control,
    register,
    reset,
    watch,
    handleSubmit,
    getValues,
    formState,
  } = useForm({
    defaultValues: item,
  })

  const watchVariants = watch("variants") || []
  const [selectedVariants, setSelectedVariants] = useState<
    { label: string; value: string }[]
  >(item.variants)

  console.log("WATCH VARIANTS IS UPDATED", watchVariants)

  const { variants, refetch } = useAdminVariants({
    limit: 50,
    offset: 0,
  })

  const variantOptions = useMemo(() => {
    if (!variants) return []
    console.log("watch variants->", watchVariants)
    const groupedOptions = convertVariantsToGroupedOptions(variants)
    return groupedOptions as any[]
  }, [variants, watchVariants])

  const handleSave = (data) => {
    console.log("handle save...", data)
    const cleaned = convertEmptyStringToNull(data)
    cleaned.variants = selectedVariants?.map((v) => v.value) || []
    onSubmit(cleaned)
  }

  return (
    <Modal handleClose={onCancel}>
      <Modal.Body
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Modal.Header handleClose={onCancel}>
          <h2 className="inter-xlarge-semibold">{title}</h2>
        </Modal.Header>
        <Modal.Content id="modal-content" className="flex-1">
          <div className="mb-8">
            <label
              tabIndex={0}
              className="inter-base-semibold mb-4 flex items-center gap-xsmall"
            >
              {"General"}
            </label>
          </div>

          <div className="grid grid-rows-1 grid-cols-2 gap-x-4 gap-y-4 mb-large">
            <Input
              label="Name"
              name="name"
              placeholder="shirts"
              ref={register()}
            />
            <Input
              label="Quantity"
              name="quantity"
              placeholder="100"
              type="number"
              ref={register()}
            />
            <div className="col-span-2">
              <SelectGroup
                setSelectedOptions={setSelectedVariants}
                selectedOptions={selectedVariants}
                options={variantOptions}
                placeholder="Search for a product"
                name="variants"
                label="Variants"
                loadOptions={async (inputValue) => {
                  const response = await medusaClient.admin.variants.list({
                    q: inputValue,
                    limit: 50,
                    offset: 0,
                  })
                  return convertVariantsToGroupedOptions(response.variants)
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex w-full justify-end gap-x-base">
            <Button
              className="w-[127px]"
              onClick={onCancel}
              size="small"
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handleSave)}
              type="submit"
              className="w-[127px]"
              size="small"
              variant="primary"
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default ItemEditor
