export const kitListToFormValuesMapper = (kitList) => {
  return {
    replenishmentFrequencyValue: kitList?.replenishmentFrequencyValue,
    replenishmentFrequencyMetric:{label:kitList?.replenishmentFrequencyMetric, value:kitList?.replenishmentFrequencyMetric} ,
    name: kitList?.name,
    items: kitListItemMapper(kitList?.items),
    customers: kitList?.customers?.map((m) => {return {label: m.email, value:m.id}}) ??[],
    allocations: kitList?.allocations,
    id: kitList?.id
  }
}

export const formValuesToCreateKitListMapper = (values) => {
  return {
    replenishmentFrequencyValue: values.replenishmentFrequencyValue,
    replenishmentFrequencyMetric: values.replenishmentFrequencyMetric.value,
    name: values.name,
    items: values.items.map((m) => { return { variants: m.variants.map((n) => n?.id ??  n.value), quantity: m.quantity } }),
    customers: values.customers.map((m) => { return m.value }),
    status: values.status || "published",
  }
}

export const formValuesToUpdateKitListMapper = (values) => {
  return {
    replenishmentFrequencyValue: values.replenishmentFrequencyValue,
    replenishmentFrequencyMetric: values.replenishmentFrequencyMetric.value,
    name: values.name,
    items: values.items.map((m) => { return { variants: m.variants.map((n) => n.value), quantity: m.quantity } }),
    customers: values.customers.map((m) => { return m.value }),
    status: values.status || "published",
  }
}

export const kitListItemMapper = (items) => items?.map((m) => { return { id: m.id, variants: m.variants.map((n) =>{return { value:n.id, label: n.title } }), quantity: m.quantity, name:m.name } }) ??[];