import React from "react"

const PdfUploadText: React.FC = () => (
  <span>
    Drop your PDF here, or{" "}
    <span className="text-violet-60">click to browse</span>
  </span>
)

export default PdfUploadText
