import React from "react"

interface EditAllocationSaveButtonProps {
  disabled: boolean
}

const EditAllocationSaveButton: React.FC<EditAllocationSaveButtonProps> = ({
  disabled,
}) => (
  <input
    className={`btn-primary ${
      disabled ? "cursor-not-allowed" : "cursor-pointer"
    } px-4 py-2 rounded-rounded self-start`}
    type="submit"
    value="Save"
    disabled={disabled}
  />
)

export default EditAllocationSaveButton
