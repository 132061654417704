import React from "react"
import { ApprovalGroupForm, OnApprovalGroupSubmit } from "./form"
import { ApprovalGroup } from "./types"
import { useQuery } from "react-query"
import { medusaClient } from "../../medusa-client"
import { navigate } from "gatsby"
import useNotification from "../../hooks/use-notification"

export function Edit({ id }) {
  const notification = useNotification()
  const { data, refetch } = useQuery(["fetch_approval_group", id], async () => {
    return medusaClient.admin.client.request(
      "GET",
      `/admin/approval-groups/${id}`
    )
  })

  const onSubmit = async (data: OnApprovalGroupSubmit) => {
    const result = await medusaClient.admin.client
      .request("POST", `/admin/approval-groups/${id}`, data)
      .then(() => {
        notification("Success", "Updated Approval Group", "success")
      })
      .catch((err) => {
        console.error(err)
        notification("Error", "Failed to update approval group", "error")
      })

    refetch()
    console.log("update result ->", result)
  }

  const approvalGroup = data?.approval_group

  console.log(data)

  if (!approvalGroup) {
    return <div>Loading...</div>
  }

  return (
    <ApprovalGroupForm
      actionText="Save"
      title={`Edit Approval Group: ${approvalGroup.name}`}
      onSubmit={onSubmit}
      approvalGroup={approvalGroup}
      onBack={() => {
        navigate(`/a/approval-groups`)
      }}
    />
  )
}
