import useMedusa from "./use-medusa"

export const useAdminArtworkTemplates = (
    query?: any,
) => {
    const data = useMedusa('artworkTemplate', query)
    return { ...data } as const
}

export const useAdminArtworkTemplate = (
    id: string
) => {
    const data = useMedusa('artworkTemplate', { id })
    return { ...data, } as const
}