import { RouteComponentProps, Router } from "@reach/router"
import React, { useContext } from "react"
import { useQuery } from "react-query"
import PlusIcon from "../../../components/fundamentals/icons/plus-icon"
import BodyCard from "../../../components/organisms/body-card"
import CustomerPermissionsTable from "../../../components/templates/customer-permissions-table/customer-permissions-table"
import { medusaClient } from "../../../medusa-client"
import CustomersPageTableHeader from "../header"
import CustomerPermissionsContext, {
  CustomerPermissionsContextContainer,
} from "./context/customer-permissions-context"
import Details from "./details"

/*
 * Customer Permissions index page
 */
function Index(_: RouteComponentProps) {
  const { showModal } = useContext(CustomerPermissionsContext)

  const actions = [
    {
      label: "New Permission",
      onClick: showModal,
      icon: (
        <span className="text-grey-90">
          <PlusIcon size={20} />
        </span>
      ),
    },
  ]

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          actionables={actions}
          customHeader={<CustomersPageTableHeader activeView="permissions" />}
        >
          <CustomerPermissionsTable />
        </BodyCard>
      </div>
    </div>
  )
}

/*
 * Customer Permissions routes
 */
function CustomerPermissions(_: RouteComponentProps) {
  return (
    <CustomerPermissionsContextContainer>
      <Router basepath="/a/customers/permissions">
        <Index path="/" />
        <Details path=":id" />
      </Router>
    </CustomerPermissionsContextContainer>
  )
}

export default CustomerPermissions
