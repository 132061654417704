import React, { useState } from "react"
import FileUploadField from "../../../../components/atoms/file-upload-field"
import Spinner from "../../../../components/atoms/spinner"
import { medusaClient } from "../../../../medusa-client"
import PdfUploadProps from "./pdfUpload.props"
import PdfUploadText from "./pdfUploadText"

const PdfUpload: React.FC<PdfUploadProps> = ({
  name,
  onUploadStatusChange,
  setUrl,
  url,
}) => {
  const [uploading, setUploading] = useState<boolean>(false)

  const uploadAndSetUrl = (files: any[]) => {
    setUploading(true)
    onUploadStatusChange(true)
    medusaClient.admin.uploads
      .create(files[0])
      .then(({ uploads }) => {
        setUrl(uploads[0].url)
      })
      .finally(() => {
        setUploading(false)
        onUploadStatusChange(false)
      })
  }

  const allowedFileTypes = ["pdf"]

  if (uploading) {
    return <Spinner />
  }

  return (
    <div className="grow flex flex-col items-center">
      <h2 className="inter-base-semibold text-grey-90 mr-1.5">{name}</h2>
      <div className="mt-base">
        {url && (
          <a className="mt-4 hover:text-purple-600" href={url} target="_blank">
            {url}
          </a>
        )}
        <div className="mt-2xlarge">
          <FileUploadField
            onFileChosen={uploadAndSetUrl}
            text={<PdfUploadText />}
            filetypes={allowedFileTypes}
            className="py-large"
          />
        </div>
      </div>
    </div>
  )
}

export default PdfUpload
