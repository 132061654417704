import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import InputField from "../../components/molecules/input"
import Button from "../../components/fundamentals/button"
import { useQuery } from "react-query"
import { medusaClient } from "../../medusa-client"
import AsyncSelect from "react-select/async"
import Select, { components } from "react-select"
import XCircleIcon from "../../components/fundamentals/icons/x-circle-icon"
import {
  ApprovalGroup,
  ApprovalGroupCondition,
  ApprovalUtilKeys,
  Rule,
} from "./types"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import ReactJson from "react-json-view"
import Tooltip from "../../components/atoms/tooltip"
import { Customer } from "@medusajs/medusa"
import { navigate } from "@reach/router"
import ArrowLeftIcon from "../../components/fundamentals/icons/arrow-left-icon"

const primaryColor = "#7c3aed"

const lighten = (amount, color) => {
  // Convert hex to RGB values
  const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16))
  // Apply lighten algorithm
  const lightenChannel = (channel) =>
    Math.round(channel + (255 - channel) * amount)
  const [lightenedR, lightenedG, lightenedB] = [r, g, b].map(lightenChannel)
  // Convert RGB back to hex
  const lightenColor = `#${((lightenedR << 16) | (lightenedG << 8) | lightenedB)
    .toString(16)
    .padStart(6, "0")}`
  return lightenColor
}
function OptionWithConditionPreview(props: any) {
  return (
    <Tooltip
      // open
      className="max-w-7xl"
      style={{
        maxWidth: 400,
      }}
      content={
        /* @ts-ignore */
        <ReactJson
          name="condition"
          displayDataTypes={false}
          style={{
            minWidth: 400,
          }}
          src={props.value.condition}
        ></ReactJson>
      }
    >
      <components.Option {...props} />
    </Tooltip>
  )
}

function CustomerSelectGroup({
  selectedOptions,
  setSelectedOptions,
  name,
  label,
  placeholder,
}) {
  type CustomerWithBranch = Customer & {
    branch: {
      id: string
      name: string
    }
  }

  function groupCustomersByBranch(customers: Customer[]) {
    if (customers?.length > 0) {
      const branchMap = (customers as CustomerWithBranch[]).reduce(
        (acc, curr) => {
          const branch = curr?.branch

          const key = branch?.name || "No Branch"

          if (acc[key]) {
            acc[key].push(curr)
          } else {
            acc[key] = [curr]
          }

          return acc
        },
        {} as Record<string, Customer[]>
      )

      return branchMap
    }

    return {}
  }

  function convertToGroupedOptions<T extends { email: string; id: string }>(
    map: Record<string, T[]>
  ) {
    const options: Array<{
      label: string
      options: Array<{ label: string; value: string }>
    }> = []

    for (const [key, values] of Object.entries(map)) {
      options.push({
        label: key,
        options: values.map((obj) => {
          return {
            label: obj.email,
            value: obj.id,
          }
        }),
      })
    }

    return options
  }

  async function getCustomers({
    q,
    limit,
    offset,
  }: {
    q?: string
    limit: number
    offset: number
  }) {
    let url = `/admin/customers/populated?offset=${offset}&limit=${limit}`

    if (q && q !== "") {
      url += `&q=${q}`
    }

    const result = await medusaClient.admin.client.request("GET", url)
    return result
  }
  const { data: customerData } = useQuery(`customer-query`, () => {
    return getCustomers({
      limit: 15,
      offset: 0,
    })
  })

  async function loadOptions(inputValue) {
    const response = await getCustomers({
      q: inputValue,
      limit: 15,
      offset: 0,
    })
    const grouped = groupCustomersByBranch(response.customers)
    const options = convertToGroupedOptions(grouped)
    return options
  }

  const options = useMemo(() => {
    if (customerData?.customers?.length > 0) {
      const grouped = groupCustomersByBranch(customerData)
      const options = convertToGroupedOptions(grouped)
      return options
    }

    return []
  }, [])

  return (
    <SelectGroup
      setSelectedOptions={setSelectedOptions}
      selectedOptions={selectedOptions}
      options={options as any}
      placeholder={placeholder}
      name={name}
      label={label}
      loadOptions={loadOptions as any}
    />
  )
}

function SelectGroup(props: {
  placeholder: string
  name: string
  label: string
  selectedOptions: Array<{ label: string; value: any[] | any }>
  setSelectedOptions: (value: any) => void
  options: Array<{ label: string; value: any }>
  loadOptions: (
    inputValue: string
  ) => Promise<Array<{ label: string; value: any }>>
}) {
  const [showSelected, setShowSelected] = React.useState(true)

  return (
    <div className="space-y-4 pt-4">
      <span className="inter-base-semibold">{props.label}</span>
      <div>
        <AsyncSelect
          isMulti
          defaultOptions={true}
          value={props.selectedOptions}
          name={props.name}
          controlShouldRenderValue={false}
          isClearable
          hideSelectedOptions={true}
          closeMenuOnSelect={false}
          placeholder={props.placeholder || "Select..."}
          options={props.options}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            // ClearIndicator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: primaryColor,
              primary25: lighten(0.25, primaryColor),
              primary50: lighten(0.5, primaryColor),
              primary75: lighten(0.75, primaryColor),
            },
          })}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
            }),
          }}
          menuPlacement="auto"
          maxMenuHeight={200}
          loadOptions={props.loadOptions}
          onChange={(value) => {
            props.setSelectedOptions(value as any)
          }}
        />

        {/* <div className="flex space-x-2 pb-2">
          <button onClick={() => setShowSelected(!showSelected)}>
            <span className="text-xs text-gray-600">
              {showSelected ? "Hide selected" : "Show selected"}
            </span>
          </button>
        </div> */}
      </div>
      <div
        className="flex flex-wrap gap-2 p-2 rounded border py-4"
        style={{
          minHeight: 76,
          // maxHeight: 200,
          // overflowY: "auto",
        }}
      >
        {props.selectedOptions?.length > 0 ? (
          props.selectedOptions?.map((c) => {
            return (
              <div className="flex items-center justify-between border p-2 rounded-lg space-x-2 bg-white text-xs">
                <span className="">{c.label}</span>
                <button
                  onClick={() => {
                    props.setSelectedOptions(
                      props.selectedOptions.filter((s) => s.value !== c.value)
                    )
                  }}
                >
                  <XCircleIcon color="#9CA3AF" className="mr-2" />
                </button>
              </div>
            )
          })
        ) : (
          <div className="flex justify-center items-center w-full">
            <span className="text-xs text-gray-600 text-center">
              No selected options
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
export interface OnApprovalGroupSubmit {
  name: string
  condition: ApprovalGroupCondition
  customers: string[]
  approvers: string[]
}

export function ApprovalGroupForm({
  title,
  approvalGroup,
  onSubmit: onSave,
  onBack,
  actionText,
}: {
  title: string
  onSubmit: (data: OnApprovalGroupSubmit) => Promise<void>
  approvalGroup?: ApprovalGroup
  onBack: () => void
  actionText: string
}) {
  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: approvalGroup?.name || "",
    },
  })

  const [selectedCustomers, setSelectedCustomers] = React.useState(
    approvalGroup
      ? approvalGroup.customers?.map((c) => ({
          label: c.email,
          value: c.id,
        }))
      : []
  )

  const [selectedApprovers, setSelectedApprovers] = React.useState(
    approvalGroup
      ? approvalGroup.approvers?.map((c) => ({
          label: c.email,
          value: c.id,
        }))
      : []
  )

  // Sync the data
  useEffect(() => {
    setSelectedCustomers(
      approvalGroup
        ? approvalGroup.customers?.map((c) => ({
            label: c.email,
            value: c.id,
          }))
        : []
    )
    setSelectedApprovers(
      approvalGroup
        ? approvalGroup.approvers?.map((c) => ({
            label: c.email,
            value: c.id,
          }))
        : []
    )

    setValue("name", approvalGroup?.name || "")
    setCondition(
      approvalGroup?.condition || {
        satisfy: "ALL",
        rules: [],
      }
    )
  }, [approvalGroup])

  const onSubmit = (data: any) => {
    console.log("Approval Group Form On Submit: ", data)
    onSave({
      name: data.name,
      approvers: selectedApprovers?.map((c) => c.value),
      condition: condition,
      customers: selectedCustomers?.map((c) => c.value),
    })
  }
  const [copyCondition, setCopyCondition] = useState<{
    label: string
    value: any
  } | null>(null)

  const [condition, setCondition] = useState<ApprovalGroupCondition>(
    approvalGroup?.condition || {
      satisfy: "ALL",
      rules: [],
    }
  )

  return (
    <div className="bg-white p-8 px-0">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col h-full overflow-y-auto px-8"
      >
        <div className="pb-6">
          <div className="pt-2">
            <Button
              type="button"
              className="py-2 px-0 mb-4"
              onClick={onBack}
              variant={"ghost"}
            >
              <ArrowLeftIcon />
              <span>Go back</span>
            </Button>
          </div>

          <div className="flex justify-between items-center">
            <h1>
              <span className="flex inter-large-semibold gap-x-base">
                {title}
              </span>
            </h1>
            <Button
              variant="primary"
              size="medium"
              type="submit"
              className="px-6"
            >
              {actionText}
            </Button>
          </div>
        </div>

        <InputField
          className="bg-white"
          label="Name"
          name="name"
          ref={register}
        />
        <div className="grid grid-cols-2 gap-x-4 h-full">
          <CustomerSelectGroup
            setSelectedOptions={setSelectedCustomers}
            selectedOptions={selectedCustomers}
            placeholder="Search for a customer"
            name="customers"
            label="Customers"
          />
          <CustomerSelectGroup
            setSelectedOptions={setSelectedApprovers}
            selectedOptions={selectedApprovers}
            placeholder="Search for a customer"
            name="approvers"
            label="Approvers"
          />
        </div>
        <div className="pt-4 h-full space-y-4">
          <h1>
            <span className="inter-base-semibold text-lg">Condition</span>
          </h1>
          <div className="flex space-x-2 items-end justify-start">
            <div className="space-y-2 w-96 grid">
              <label htmlFor="import-condition" className="text-sm">
                Copy condition from existing approval group
              </label>
              <AsyncSelect
                id="import-condition"
                name="import-condition"
                defaultOptions
                controlShouldRenderValue={true}
                components={{
                  Option: (props) => {
                    return <OptionWithConditionPreview {...props} />
                  },
                }}
                value={copyCondition}
                onChange={(newValue: any) => {
                  if (newValue?.value?.condition) {
                    setCopyCondition(newValue)
                  }
                }}
                placeholder="Search for an existing approval group"
                loadOptions={async (inputValue) => {
                  let url = `/admin/approval-groups`
                  if (inputValue && inputValue != "") {
                    url += `?q=${inputValue}`
                  }
                  const response = await medusaClient.admin.client.request(
                    "GET",
                    url
                  )
                  return response.approval_groups.map((c) => ({
                    label: c.name,
                    value: c,
                  }))
                }}
              ></AsyncSelect>
            </div>
            <div>
              <Button
                disabled={copyCondition == null}
                variant="primary"
                size="small"
                className="h-9"
                onClick={() => {
                  if (copyCondition?.value?.condition) {
                    setCondition(copyCondition.value.condition)
                    setCopyCondition(null)
                  }
                }}
              >
                Copy
              </Button>
            </div>
          </div>
          <Condition condition={condition} setCondition={setCondition} />
        </div>
      </form>
    </div>
  )
}

function Condition({
  condition,
  setCondition,
}: {
  condition: ApprovalGroupCondition
  setCondition: (value: ApprovalGroupCondition) => void
}) {
  const [newRule, setNewRule] = useState<Partial<Rule<ApprovalUtilKeys>>>({})

  const handleAddNewRule = () => {
    if (!newRule.property || !newRule.op || !newRule.value) {
      return
    }

    const newRules = [...(condition.rules || [])]
    newRules.push(newRule as any)
    setCondition({ ...condition, rules: newRules })
    setNewRule({})
  }

  return (
    <div className="p-4 space-y-4 border rounded">
      <div className="space-y-2 max-w-sm">
        <label htmlFor="satisfy">Satisfy</label>
        <Select
          menuPlacement="top"
          name="satisfy"
          value={{
            label: condition.satisfy,
            value: condition.satisfy,
          }}
          defaultValue={{
            label: condition.satisfy,
            value: condition.satisfy,
          }}
          onChange={(value) => {
            const v = value?.value || "ANY"
            setCondition({ ...condition, satisfy: v })
          }}
          options={[
            {
              label: "ALL",
              value: "ALL",
            },
            {
              label: "ANY",
              value: "ANY",
            },
          ]}
        />
      </div>
      <div className="gap-y-2 grid border p-4 rounded">
        <div className="py-2">
          <h1>Rules</h1>
        </div>
        <div className="border p-4 rounded bg-white">
          <div className="grid grid-cols-7 gap-x-2">
            <div className="flex flex-col col-span-2">
              <label htmlFor="property">Property</label>
              <Select
                menuPlacement="top"
                name="property"
                className="font-mono"
                value={{
                  label: newRule.property?.replace("utils.", ""),
                  value: newRule.property,
                }}
                onChange={(value) => {
                  setNewRule({ ...newRule, property: value?.value })
                }}
                options={[
                  {
                    label: "cartWeight",
                    value: "utils.cartWeight",
                  },
                  {
                    label: "cartTotal",
                    value: "utils.cartTotal",
                  },
                  {
                    label: "collectionTitles",
                    value: "utils.collectionTitles",
                  },
                  {
                    label: "variantSKUs",
                    value: "utils.variantSKUs",
                  },
                  {
                    label: "customerEmail",
                    value: "utils.customerEmail",
                  },
                  {
                    label: "supplierNames",
                    value: "utils.supplierNames",
                  },
                  // {
                  //   label: "skuToCount",
                  //   value: "utils.skuToCount",
                  // },
                ]}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <label htmlFor="operator">Operator</label>
              <Select
                value={{
                  label: newRule.op,
                  value: newRule.op,
                }}
                name="operator"
                className="h-full font-mono"
                menuPlacement="top"
                onChange={(value) => {
                  setNewRule({ ...newRule, op: value?.value })
                }}
                options={[
                  { label: "eq", value: "eq" },
                  { label: "ne", value: "ne" },
                  { label: "neq", value: "neq" },
                  { label: "gt", value: "gt" },
                  { label: "gte", value: "gte" },
                  { label: "lt", value: "lt" },
                  { label: "lte", value: "lte" },
                  { label: "startsWith", value: "startsWith" },
                  { label: "endsWith", value: "endsWith" },
                  { label: "contains", value: "contains" },
                  { label: "present", value: "present" },
                  { label: "empty", value: "empty" },
                  { label: "absent", value: "absent" },
                  { label: "all", value: "all" },
                  { label: "some", value: "some" },
                  { label: "none", value: "none" },
                  { label: "crosses", value: "crosses" },
                ]}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <label htmlFor="value">Value</label>
              <input
                name="value"
                className="bg-white h-9 px-2 rounded border font-mono"
                value={newRule.value || ""}
                onChange={(e) => {
                  setNewRule({ ...newRule, value: e.target.value })
                }}
              />
            </div>
            <div className="flex justify-center items-end pb-1 pt-4">
              <Button
                variant="primary"
                size="small"
                className="px-2 border"
                onClick={handleAddNewRule}
              >
                <PlusIcon />
                Add Rule
              </Button>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col space-y-4 bg-white border rounded"
          style={{
            minHeight: "200px",
          }}
        >
          {condition?.rules?.length === 0 ? (
            <div className="py-4 flex justify-center text-gray-400 h-full items-center">
              Add some rules
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-300 bg-gray-50">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Property
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Operator
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Value
                  </th>
                  <th
                    scope="col"
                    className="w-8 relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {condition?.rules?.map((rule, index) => (
                  <tr
                    key={`rule-${index}`}
                    className="border-b border-gray-300"
                  >
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0 font-mono">
                      {rule.property?.replace("utils.", "")}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 font-mono">
                      {rule.op}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 font-mono">
                      {rule.value}
                    </td>
                    <td className="">
                      <Button
                        variant="ghost"
                        size="small"
                        onClick={() => {
                          const newRules = [...(condition.rules || [])]
                          newRules.splice(index, 1)
                          setCondition({ ...condition, rules: newRules })
                        }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}
