import { useAdminKitList, useAdminUpdateKitList } from "@iq/medusa-react"
import React, { useEffect, useState } from "react"
import { FieldValues } from "react-hook-form"
import toast from "react-hot-toast"
import Spinner from "../../components/atoms/spinner"
import Toaster from "../../components/declarative-toaster"
import FormToasterContainer from "../../components/molecules/form-toaster"
import useNotification from "../../hooks/use-notification"

import { getErrorMessage } from "../../utils/error-messages"
import { checkForDirtyState } from "../../utils/form-helpers"
import { handleFormError } from "../../utils/handle-form-error"
import KitListForm from "./kit-list-form"
import {
  KitListFormProvider,
  useKitListForm,
} from "./kit-list-form/form/kitList-form-context"
import {
  formValuesToUpdateKitListMapper,
  kitListToFormValuesMapper,
} from "./kit-list-form/form/mappers"

const EditProductPage = ({ id }) => {
  const notification = useNotification()
  const { kit_list, isLoading } = useAdminKitList(id, {
    keepPreviousData: false,
  })
  const updateKitList = useAdminUpdateKitList(id)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (data) => {
    setSubmitting(true)

    const newData = {
      ...data,
    }

    updateKitList.mutate(formValuesToUpdateKitListMapper(newData), {
      onSuccess: () => {
        setSubmitting(false)
        notification("Success", "KitList updated successfully", "success")
      },
      onError: (error) => {
        setSubmitting(false)
        notification("Error", getErrorMessage(error), "error")
      },
    })
  }

  return isLoading ? (
    <div className="w-full pt-2xlarge flex items-center justify-center">
      <Spinner size={"large"} variant={"secondary"} />
    </div>
  ) : (
    <KitListFormProvider
      onSubmit={onSubmit}
      kitList={kitListToFormValuesMapper(kit_list)}
    >
      <KitListForm kitList={kitListToFormValuesMapper(kit_list)} isEdit />
      <UpdateNotification isLoading={isLoading} />
    </KitListFormProvider>
  )
}

const TOAST_ID = "edit-kitlist-dirty"

const UpdateNotification = ({ isLoading = false }) => {
  const { formState, onSubmit, handleSubmit, resetForm } = useKitListForm()
  const [visible, setVisible] = useState(false)
  const [blocking, setBlocking] = useState(true)

  const onUpdate = (values: FieldValues) => {
    onSubmit({ ...values })
  }

  useEffect(() => {
    const timeout = setTimeout(setBlocking, 300, false)
    return () => clearTimeout(timeout)
  }, [])

  const isDirty = checkForDirtyState(formState.dirtyFields, {})

  useEffect(() => {
    if (!blocking) {
      setVisible(isDirty)
    }

    return () => {
      toast.dismiss(TOAST_ID)
    }
  }, [isDirty])

  return (
    <Toaster
      visible={visible}
      duration={Infinity}
      id={TOAST_ID}
      position="bottom-right"
    >
      <FormToasterContainer isLoading={isLoading}>
        <FormToasterContainer.Actions>
          <FormToasterContainer.ActionButton
            onClick={handleSubmit(onUpdate, handleFormError)}
          >
            Save
          </FormToasterContainer.ActionButton>
          <FormToasterContainer.DiscardButton onClick={resetForm}>
            Discard
          </FormToasterContainer.DiscardButton>
        </FormToasterContainer.Actions>
      </FormToasterContainer>
    </Toaster>
  )
}

export default EditProductPage
