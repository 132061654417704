import { useParams } from "@reach/router"
import { navigate } from "gatsby"
import { useAdminCustomers, useAdminDeleteKitList } from "@iq/medusa-react"
import React from "react"
import { Controller } from "react-hook-form"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import Input from "../../../../components/molecules/input"
import Select from "../../../../components/molecules/select"
import StatusSelector from "../../../../components/molecules/status-selector"
import BodyCard from "../../../../components/organisms/body-card"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import { useKitListForm } from "../form/kitList-form-context"
import { useAdminUpdateKitList } from "../../../../hooks/use-admin-kitList"
import DuplicateIcon from "../../../../components/fundamentals/icons/duplicate-icon"
import useCopyKitList from "../../../../components/templates/kit-list-table/use-copy-kit-list"
import RefreshIcon from "../../../../components/fundamentals/icons/refresh-icon"
import { medusaClient } from "../../../../medusa-client"

const General = ({ isEdit = false, kitList }) => {
  const { register, control, customers, setCustomers } = useKitListForm()

  const customerOptions =
    useAdminCustomers().customers?.map((m) => {
      return { label: m.email, value: m.id }
    }) || []

  return (
    <>
      <GeneralBodyCard
        isEdit={isEdit}
        kitList={kitList}
        title="General"
        subtitle="Add a new kit list "
      >
        <div className="grid grid-rows-2 grid-cols-2 gap-x-8 gap-y-4 mb-large">
          <Input
            label="Name"
            name="name"
            placeholder="front of house men"
            ref={register({
              required: "Name is required",
            })}
            required
          />
          <Controller
            as={Select}
            isMultiSelect
            enableSearch
            control={control}
            label="Customers"
            name="customers"
            placeholder="Select Customers..."
            hasSelectAll
            options={customerOptions}
            required
            ref={register({
              required: "Customers are required",
            })}
          />
          <Input
            label="Replenishment Frequency Value"
            name="replenishmentFrequencyValue"
            type="number"
            min={1}
            required
            ref={register({
              min: 1,
              required: "Replenishment frequency value is required",
            })}
          />
          <Controller
            required
            as={Select}
            enableSearch
            control={control}
            label="Replenishment frequency metric"
            name="replenishmentFrequencyMetric"
            placeholder="Select replenishment frequency metric..."
            hasSelectAll
            ref={register({
              required: "Replenishment frequency metric is required",
            })}
            defaultValue={{
              label: "months",
              value: "months",
            }}
            options={[
              { label: "seconds", value: "seconds" },
              { label: "minutes", value: "minutes" },
              { label: "days", value: "days" },
              { label: "months", value: "months" },
            ]}
          />
        </div>
      </GeneralBodyCard>
    </>
  )
}

const GeneralBodyCard = ({ isEdit, kitList, ...props }) => {
  const params = useParams()
  const dialog = useImperativeDialog()
  const notification = useNotification()
  const updateKitList = useAdminUpdateKitList(params?.id)
  const deleteKitList = useAdminDeleteKitList(params?.id)
  const copyKitList = useCopyKitList()

  const replenishKitList = async function (kitlist) {
    const shouldReplenish = await dialog({
      heading: "Force Replenish Kit List ",
      text: "Are you sure you want to replenish this KitList",
    })

    if (!shouldReplenish) {
      return null
    }

    console.log("To replenish kit list...")
    const response = await medusaClient.admin.client.request(
      "POST",
      `/admin/kit_lists/replenish/${kitlist.id}`
    )

    console.log("Replenished kit list", response)
    return response
  }

  const onDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete KitList",
      text: "Are you sure you want to delete this KitList",
    })
    if (shouldDelete) {
      deleteKitList.mutate(undefined, {
        onSuccess: () => {
          notification("Success", "KitList deleted successfully", "success")
          navigate("/a/kit-lists/")
        },
        onError: (err) => {
          notification("Ooops", getErrorMessage(err), "error")
        },
      })
    }
  }

  const onStatusChange = async () => {
    const newStatus = kitList?.status === "published" ? "draft" : "published"
    updateKitList.mutate(
      {
        status: newStatus,
      },
      {
        onSuccess: () => {
          const pastTense = newStatus === "published" ? "published" : "drafted"
          notification(
            "Success",
            `Product ${pastTense} successfully`,
            "success"
          )
        },
        onError: (err) => {
          notification("Ooops", err, "error")
        },
      }
    )
  }

  const actionables: any[] = isEdit
    ? [
        {
          label: "Force Replenish",
          onClick: () => replenishKitList(kitList),
          icon: <RefreshIcon size={20} />,
        },
        {
          label: "Duplicate",
          onClick: () => copyKitList(kitList),
          icon: <DuplicateIcon size={20} />,
        },
        {
          label: "Delete KitList",
          onClick: onDelete,
          variant: "danger" as const,
          icon: <TrashIcon />,
        },
      ]
    : []

  return (
    <BodyCard
      actionables={isEdit ? actionables : undefined}
      forceDropdown
      status={
        isEdit ? (
          <StatusSelector
            isDraft={kitList?.status === "draft"}
            activeState="Published"
            draftState="Draft"
            onChange={onStatusChange}
          />
        ) : undefined
      }
      {...props}
    />
  )
}

export default General
