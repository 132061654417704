import { useAdminUpdateCustomer } from "@iq/medusa-react"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import Button from "../../../components/fundamentals/button"
import InputField from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import useNotification from "../../../hooks/use-notification"
import Select from "../../../components/molecules/select"
import { useQuery } from "react-query"
import { medusaClient } from "../../../medusa-client"
import Spinner from "../../../components/atoms/spinner"
import Checkbox from "../../../components/atoms/checkbox"

const EditCustomerModal = ({ handleClose, customer }) => {
  const { register, reset, handleSubmit, control } = useForm()

  const updateCustomer = useAdminUpdateCustomer(customer.id)

  const notification = useNotification()

  const fetchBranches = async () =>
    medusaClient.admin.client.request("GET", "/admin/branch?limit=999999")
  const { data: branchData, isLoading: branchDataLoading } = useQuery(
    ["fetch_branches"],
    () => fetchBranches(),
    {
      keepPreviousData: true,
    }
  )

  const fetchKitLists = async () =>
    medusaClient.admin.client.request("GET", "/admin/kit_lists?limit=999999")
  const { data: kitListData, isLoading: kitListDataLoading } = useQuery(
    ["fetch_kit_lists"],
    () => fetchKitLists(),
    {
      keepPreviousData: true,
    }
  )

  const fetchPermissions = async () =>
    medusaClient.admin.client.request(
      "GET",
      "/admin/customer-permissions?limit=999999"
    )
  const { data: permissionsData, isLoading: permissionsDataLoading } = useQuery(
    ["fetch_permissions"],
    () => fetchPermissions(),
    {
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    reset({
      is_blocked: customer.is_blocked,
      name: customer.name || "",
      email: customer.email || "",
      password: "",
      branch: branchValue,
      kit_list: kitListValue,
      permissions: permissionsValue,
    })
  }, [branchData, kitListData, permissionsData])

  if (branchDataLoading || kitListDataLoading || permissionsDataLoading) {
    return <Spinner />
  }

  const branchOptions = branchData?.branches.map((branch) => ({
    label: branch?.name,
    value: branch?.id,
    disabled: false,
  }))
  const branchValue = {
    label: customer?.branch?.name,
    value: customer?.branch?.id,
  }

  const kitListOptions = kitListData?.kit_lists.map((kitList) => ({
    label: kitList?.name,
    value: kitList?.id,
    disabled: false,
  }))
  const kitListValue = {
    label: customer?.kit_list?.name,
    value: customer?.kit_list?.id,
  }

  const permissionsOptions = permissionsData?.customerPermissions.map(
    (permissions) => ({
      label: permissions?.name,
      value: permissions?.id,
      disabled: false,
    })
  )
  const permissionsValue = {
    label: customer?.permissions?.name,
    value: customer?.permissions?.id,
  }

  const submit = (data) => {
    const payload: any = {
      email: data?.email,
      name: data?.name,
      branch: data?.branch?.value,
      kit_list: data?.kit_list.value,
      permissions: data?.permissions.value,
      is_blocked: data?.is_blocked,
    }
    if (data?.password) {
      payload.password = data.password
    }
    updateCustomer.mutate(payload, {
      onSuccess: () => {
        handleClose()
        notification("Success", "Successfully updated customer", "success")
      },
      onError: (error: any) => {
        notification(
          "Error",
          error?.response?.data?.error?.message?.message?.message ??
            JSON.stringify(error?.response?.data),
          "error"
        )
      },
    })
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">Customer Details</span>
        </Modal.Header>
        <Modal.Content>
          <div className="inter-base-semibold text-grey-90 mb-4">General</div>
          <div className="grid grid-cols-2 gap-y-2 gap-x-2">
            <InputField
              label="Name"
              name="name"
              placeholder="John Smith"
              ref={register}
            />
            <InputField
              label="Email"
              name="email"
              placeholder="john.smith@example.com"
              ref={register}
            />
            <InputField
              label="Password"
              name="password"
              placeholder="********"
              ref={register}
            />
          </div>
          <div className="py-4">
            <Checkbox label="Blocked" name="is_blocked" ref={register} />
          </div>
          <div className="inter-base-semibold text-grey-90 my-4">Other</div>
          <div className="grid grid-cols-2 gap-y-2 gap-x-2">
            <Controller
              as={Select}
              control={control}
              value={branchValue}
              enableSearch
              label="Branch"
              name="branch"
              placeholder="Select Branch..."
              options={branchOptions}
              clearSelected
            />
            <Controller
              as={Select}
              control={control}
              value={kitListValue}
              enableSearch
              label="Kit-list"
              name="kit_list"
              placeholder="Select Kit-list..."
              options={kitListOptions}
              clearSelected
            />
            <Controller
              as={Select}
              control={control}
              value={permissionsValue}
              enableSearch
              label="Permissions"
              name="permissions"
              placeholder="Select Permissions..."
              options={permissionsOptions}
              clearSelected
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className="w-full flex justify-end">
            <Button
              variant="ghost"
              size="small"
              onClick={handleClose}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              loading={updateCustomer.isLoading}
              variant="primary"
              className="min-w-[100px]"
              size="small"
              onClick={handleSubmit(submit)}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default EditCustomerModal
