import React from "react"
import { formatDatetimeForInput } from "../../../utils/time"

interface EditAllocationCreatedAtFieldProps {
  createdAt: Date
}

const EditAllocationCreatedAtField: React.FC<EditAllocationCreatedAtFieldProps> = ({
  createdAt,
}) => (
  <div className="text-gray-400 items-start bg-grey-5 p-3 flex flex-col border border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
    <label htmlFor="created-at">Created at:</label>
    <input
      type="datetime-local"
      id="created-at"
      name="created-at"
      value={formatDatetimeForInput(createdAt.toString())}
      disabled
    />
  </div>
)

export default EditAllocationCreatedAtField
