import moment from "moment"
import React, { useMemo } from "react"
import Tooltip from "../../atoms/tooltip"

const useAllocationTableColumn = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Customer Email",
        accessor: "customer",
        Cell: ({ cell: { value } }) => <div>{value.email}</div>,
      },
      {
        Header: "Kit-list Name",
        accessor: "kit_list",
        Cell: ({ cell: { value } }) => <div>{value?.name}</div>,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <Tooltip content={moment(value).format("DD MMM YYYY hh:mm A")}>
            {moment(value).format("DD MMM YYYY")}
          </Tooltip>
        ),
      },
      {
        Header: "Next Replenishment",
        accessor: "nextReplenishment",
        Cell: ({ cell: { value } }) => (
          <Tooltip content={moment(value).format("DD MMM YYYY hh:mm A")}>
            {moment(value).format("DD MMM YYYY")}
          </Tooltip>
        ),
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: ({ cell: { value } }) => {
          return <div>{value ? "Yes" : "No"}</div>
        },
      },
    ],
    []
  )

  return [columns]
}

export default useAllocationTableColumn
