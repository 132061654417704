import useMedusa from "./use-medusa"

export const useAdminKitLists = (
    query?: any,
) => {
    const data = useMedusa('getKitList', query)
    return { ...data } as const
}

export const useAdminKitList = (
    id: string
) => {
    const data = useMedusa('getKitList', { id })
    return { ...data, } as const
}


export const useAdminUpdateKitList = (
    id: string
) => {
    const data = useMedusa('kitList', { id })
    return { ...data, } as const
}

export const useAdminKitListItem = (
    id: any,
    itemId: any
) => {
    console.log([id, itemId]);
    const data = useMedusa('kitListItem', { id, itemId })
    return { ...data, } as const
}