import React from "react"
import { formatDatetimeForInput } from "../../../utils/time"

interface EditAllocationLastReplenishmentFieldProps {
  handleChangeLastReplenishment: React.ChangeEventHandler<HTMLInputElement>
  lastReplenishment: Date
}

const EditAllocationLastReplenishmentField: React.FC<EditAllocationLastReplenishmentFieldProps> = ({
  handleChangeLastReplenishment,
  lastReplenishment,
}) => (
  <div className="items-start bg-grey-5 p-3 flex flex-col border border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
    <label htmlFor="last-replenishment">Last replenishment:</label>
    <input
      type="datetime-local"
      id="last-replenishment"
      name="last-replenishment"
      value={formatDatetimeForInput(lastReplenishment.toString())}
      onChange={handleChangeLastReplenishment}
    />
  </div>
)

export default EditAllocationLastReplenishmentField
