import { Address } from "@medusajs/medusa"
import { navigate } from "gatsby"
import { sum } from "lodash"
import {
  useAdminCancelOrder,
  useAdminOrder,
  useAdminRegion,
  useAdminUpdateOrder,
} from "@iq/medusa-react"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import Avatar from "../../../components/atoms/avatar"
import CopyToClipboard from "../../../components/atoms/copy-to-clipboard"
import Spinner from "../../../components/atoms/spinner"
import Tooltip from "../../../components/atoms/tooltip"
import Badge from "../../../components/fundamentals/badge"
import DetailsIcon from "../../../components/fundamentals/details-icon"
import CancelIcon from "../../../components/fundamentals/icons/cancel-icon"
import ClipboardCopyIcon from "../../../components/fundamentals/icons/clipboard-copy-icon"
import DollarSignIcon from "../../../components/fundamentals/icons/dollar-sign-icon"
import MailIcon from "../../../components/fundamentals/icons/mail-icon"
import TruckIcon from "../../../components/fundamentals/icons/truck-icon"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import BodyCard from "../../../components/organisms/body-card"
import RawJSON from "../../../components/organisms/raw-json"
import useClipboard from "../../../hooks/use-clipboard"
import useImperativeDialog from "../../../hooks/use-imperative-dialog"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import AddressModal from "./address-modal"
import CreateFulfillmentModal from "./create-fulfillment"
import EmailModal from "./email-modal"
import MarkShippedModal from "./mark-shipped"
import OrderLine from "./order-line"
import CreateRefundModal from "./refund"
import {
  DisplayTotal,
  FormattedAddress,
  OrderStatusComponent,
  PaymentDetails,
} from "./templates"

const OrderDetails = ({ id }) => {
  const dialog = useImperativeDialog()

  const [addressModal, setAddressModal] = useState<null | {
    address: Address
    type: "billing" | "shipping"
  }>(null)

  const [emailModal, setEmailModal] = useState<null | {
    email: string
  }>(null)

  const [showFulfillment, setShowFulfillment] = useState(false)
  const [showRefund, setShowRefund] = useState(false)
  const [fullfilmentToShip, setFullfilmentToShip] = useState(null)

  const { order, isLoading } = useAdminOrder(id)

  const cancelOrder = useAdminCancelOrder(id)
  const updateOrder = useAdminUpdateOrder(id)

  // @ts-ignore
  const { region } = useAdminRegion(order?.region_id, {
    enabled: !!order?.region_id,
  })

  const notification = useNotification()

  const [, handleCopy] = useClipboard(`${order?.display_id!}`, {
    successDuration: 5500,
    onCopied: () => notification("Success", "Order ID copied", "success"),
  })

  const [, handleCopyEmail] = useClipboard(order?.email!, {
    successDuration: 5500,
    onCopied: () => notification("Success", "Email copied", "success"),
  })

  // @ts-ignore
  useHotkeys("esc", () => navigate("/a/orders"))
  useHotkeys("command+i", handleCopy)

  const { hasMovements, swapAmount, manualRefund, swapRefund, returnRefund } =
    useMemo(() => {
      let manualRefund = 0
      let swapRefund = 0
      let returnRefund = 0

      const swapAmount = sum(order?.swaps?.map((s) => s.difference_due) || [0])

      if (order?.refunds?.length) {
        order.refunds.forEach((ref) => {
          if (ref.reason === "other" || ref.reason === "discount") {
            manualRefund += ref.amount
          }
          if (ref.reason === "return") {
            returnRefund += ref.amount
          }
          if (ref.reason === "swap") {
            swapRefund += ref.amount
          }
        })
      }
      return {
        hasMovements:
          swapAmount + manualRefund + swapRefund + returnRefund !== 0,
        swapAmount,
        manualRefund,
        swapRefund,
        returnRefund,
      }
    }, [order])

  const handleDeleteOrder = async () => {
    const shouldDelete = await dialog({
      heading: "Cancel order",
      text: "Are you sure you want to cancel the order?",
    })

    if (!shouldDelete) {
      return
    }

    return cancelOrder.mutate(void {}, {
      onSuccess: () =>
        notification("Success", "Successfully canceled order", "success"),
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }

  const handleUpdateAddress = async ({ data, type }) => {
    const updateObj = {}

    if (type === "shipping") {
      updateObj["shipping_address"] = {
        ...data,
      }
    } else {
      updateObj["billing_address"] = {
        ...data,
      }
    }

    return updateOrder.mutate(updateObj, {
      onSuccess: () => {
        notification("Success", "Successfully updated address", "success")
        setAddressModal(null)
      },
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }

  const handleUpdateEmail = async ({ email }) => {
    const updateObj = email ? { email } : {}

    return updateOrder.mutate(updateObj, {
      onSuccess: () => {
        notification(
          "Success",
          "Successfully updated the email address",
          "success"
        )
        setEmailModal(null)
      },
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }

  const customerActionables = [
    {
      label: "Go to Customer",
      icon: <DetailsIcon size={"20"} />,
      onClick: () => navigate(`/a/customers/${order?.customer.id}`),
    },
    {
      label: "Edit Shipping Address",
      icon: <TruckIcon size={"20"} />,
      onClick: () =>
        setAddressModal({
          address: order?.shipping_address,
          type: "shipping",
        }),
    },
  ]

  if (order?.billing_address) {
    customerActionables.push({
      label: "Edit Billing Address",
      icon: <DollarSignIcon size={"20"} />,
      onClick: () => {
        if (order.billing_address) {
          setAddressModal({
            address: order?.billing_address,
            type: "billing",
          })
        }
      },
    })
  }

  if (order?.email) {
    customerActionables.push({
      label: "Edit Email Address",
      icon: <MailIcon size={"20"} />,
      onClick: () => {
        setEmailModal({
          email: order?.email,
        })
      },
    })
  }

  return (
    <div>
      <Breadcrumb
        currentPage={"Order Details"}
        previousBreadcrumb={"Orders"}
        previousRoute="/a/orders"
      />
      {isLoading || !order ? (
        <BodyCard className="w-full pt-2xlarge flex items-center justify-center">
          <Spinner size={"large"} variant={"secondary"} />
        </BodyCard>
      ) : (
        <div className="flex w-full">
          <div className="flex flex-col h-full w-full">
            <BodyCard
              className={"w-full mb-4 min-h-[200px]"}
              customHeader={
                <Tooltip side="top" content={"Copy ID"}>
                  <div className="inter-smaller-regular text-grey-50 leading-3">
                    Display ID
                  </div>
                  <button
                    className="inter-xlarge-semibold text-grey-90 active:text-violet-90 cursor-pointer gap-x-2 flex items-center"
                    onClick={handleCopy}
                  >
                    #{order.display_id} <ClipboardCopyIcon size={16} />
                  </button>
                </Tooltip>
              }
              subtitle={moment(order.created_at).format("DD MMM YYYY hh:mm")}
              status={<OrderStatusComponent status={order?.status} />}
              forceDropdown={true}
              actionables={[
                {
                  label: "Cancel Order",
                  icon: <CancelIcon size={"20"} />,
                  variant: "danger",
                  onClick: () => handleDeleteOrder(),
                },
              ]}
            >
              <div className="flex divide-x">
                <div className="flex flex-col basis-1/2">
                  <div className="inter-smaller-regular text-grey-50">
                    Order ID
                  </div>
                  <div>{order?.id || "N/A"}</div>
                </div>
                <div className="flex flex-col basis-1/2 pl-6">
                  <div className="inter-smaller-regular text-grey-50">
                    Order Reference
                  </div>
                  <div>{order?.orderReference || "N/A"}</div>
                </div>
              </div>
              <div className="flex divide-x pt-6">
                <div className="flex flex-col basis-1/2">
                  <div className="inter-smaller-regular text-grey-50">
                    Email
                  </div>
                  <button
                    className="text-grey-90 active:text-violet-90 cursor-pointer gap-x-1 flex items-center"
                    onClick={handleCopyEmail}
                  >
                    {order?.email || "N/A"}
                    <ClipboardCopyIcon size={12} />
                  </button>
                </div>
                <div className="flex flex-col basis-1/2 pl-6">
                  <div className="inter-smaller-regular text-grey-50">
                    Cost Centre
                  </div>
                  <div>{order?.costCentre || "N/A"}</div>
                </div>
              </div>
            </BodyCard>
            <BodyCard className={"w-full mb-4 min-h-0 h-auto"} title="Summary">
              <div className="mt-6">
                <div className="flex justify-end text-center font-bold">
                  <div className="min-w-[150px]">Quantity</div>
                  <div className="min-w-[150px]">Unit Price</div>
                  <div className="min-w-[150px]">Line Total</div>
                </div>
                <div className="divide-y">
                  {order?.items?.map((item, i) => (
                    <>
                      <OrderLine
                        key={i}
                        item={item}
                        currencyCode={order?.currency_code}
                      />
                    </>
                  ))}
                </div>
                <DisplayTotal
                  currency={order?.currency_code}
                  totalAmount={order?.subtotal}
                  totalTitle={"Subtotal"}
                />
                {order?.discounts?.map((discount, index) => (
                  <DisplayTotal
                    key={index}
                    currency={order?.currency_code}
                    totalAmount={-1 * order?.discount_total}
                    totalTitle={
                      <div className="flex inter-small-regular text-grey-90 items-center">
                        Discount:{" "}
                        <Badge className="ml-3" variant="default">
                          {discount.code}
                        </Badge>
                      </div>
                    }
                  />
                ))}
                {order?.gift_cards?.map((giftCard, index) => (
                  <DisplayTotal
                    key={index}
                    currency={order?.currency_code}
                    totalAmount={-1 * order?.gift_card_total}
                    totalTitle={
                      <div className="flex inter-small-regular text-grey-90 items-center">
                        Gift card:{" "}
                        <Badge className="ml-3" variant="default">
                          {giftCard.code}
                        </Badge>
                        <div className="ml-2">
                          <CopyToClipboard
                            value={giftCard.code}
                            showValue={false}
                            iconSize={16}
                          />
                        </div>
                      </div>
                    }
                  />
                ))}
                <DisplayTotal
                  currency={order?.currency_code}
                  totalAmount={order?.shipping_total}
                  totalTitle={"Shipping"}
                />
                <DisplayTotal
                  currency={order?.currency_code}
                  totalAmount={order?.tax_total}
                  totalTitle={`Tax`}
                />
                <DisplayTotal
                  variant={"large"}
                  currency={order?.currency_code}
                  totalAmount={order?.total}
                  totalTitle={hasMovements ? "Original Total" : "Total"}
                />
                <PaymentDetails
                  manualRefund={manualRefund}
                  swapAmount={swapAmount}
                  swapRefund={swapRefund}
                  returnRefund={returnRefund}
                  paidTotal={order?.paid_total}
                  refundedTotal={order?.refunded_total}
                  currency={order?.currency_code}
                />
              </div>
            </BodyCard>
            <BodyCard
              className={"w-full mb-4 min-h-0 h-auto"}
              title="Customer"
              actionables={customerActionables}
            >
              <div className="mt-6">
                <div className="flex w-full items-center gap-4">
                  <div className="flex w-[40px] h-[40px] ">
                    <Avatar
                      user={order?.customer}
                      font="inter-large-semibold"
                      color="bg-fuschia-40"
                    />
                  </div>
                  <div>
                    <h1 className="inter-large-semibold text-grey-90">
                      {`${order?.shipping_address?.first_name} ${order?.shipping_address?.last_name}`}
                    </h1>
                    <span className="inter-small-regular text-grey-50">
                      {order?.shipping_address?.city},{" "}
                      {order?.shipping_address?.country_code}
                    </span>
                  </div>
                </div>
                <div className="flex mt-6 divide-x gap-6">
                  <div className="flex flex-col">
                    <div className="inter-small-regular text-grey-50 mb-1">
                      Contact
                    </div>
                    <div className="flex flex-col inter-small-regular">
                      <span>{order?.email}</span>
                      <span>{order?.shipping_address?.phone || ""}</span>
                    </div>
                  </div>
                  <FormattedAddress
                    title={"Shipping"}
                    addr={order?.shipping_address}
                  />
                  <FormattedAddress
                    title={"Billing"}
                    addr={order?.billing_address}
                  />
                </div>
              </div>
            </BodyCard>
            <div className="mt-large">
              <RawJSON data={order} title="Raw order" />
            </div>
          </div>
        </div>
      )}
      {addressModal && (
        <AddressModal
          handleClose={() => setAddressModal(null)}
          handleSave={(obj) => handleUpdateAddress(obj)}
          address={addressModal.address}
          type={addressModal.type}
          allowedCountries={region?.countries}
        />
      )}
      {emailModal && (
        <EmailModal
          handleClose={() => setEmailModal(null)}
          handleSave={(obj) => handleUpdateEmail(obj)}
          email={emailModal.email}
        />
      )}
      {showFulfillment && order && (
        <CreateFulfillmentModal
          orderToFulfill={order as any}
          handleCancel={() => setShowFulfillment(false)}
          orderId={order.id}
        />
      )}
      {showRefund && order && (
        <CreateRefundModal
          order={order}
          onDismiss={() => setShowRefund(false)}
        />
      )}
      {fullfilmentToShip && order && (
        <MarkShippedModal
          orderToShip={order as any}
          handleCancel={() => setFullfilmentToShip(null)}
          fulfillment={fullfilmentToShip}
          orderId={order.id}
        />
      )}
    </div>
  )
}

export default OrderDetails
