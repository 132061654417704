import React, { useEffect, useState } from "react"
import Spinner from "../../../components/atoms/spinner"
import { medusaClient } from "../../../medusa-client"
import { Allocation } from "../../../../static/types/medusa-types"
import EditAllocationActiveField from "./edit-allocation-active-field"
import EditAllocationCreatedAtField from "./edit-allocation-created-at-field"
import EditAllocationIdField from "./edit-allocation-id-field"
import EditAllocationItems from "./edit-allocation-items"
import EditAllocationLastReplenishmentField from "./edit-allocation-last-replenishment-field"
import EditAllocationNextReplenishment from "./edit-allocation-next-replenishment-field"
import EditAllocationSaveButton from "./edit-allocation-save-button"
import EditAllocationUpdatedAtField from "./edit-allocation-updated-at-field"
import EditAllocationReplenishButton from "./edit-allocation-replenish-button"
import useImperativeDialog from "../../../hooks/use-imperative-dialog"

interface EditAllocationFormProps {
  allocation: Allocation
  refetch: () => Promise<any>;
}

const EditAllocationForm: React.FC<EditAllocationFormProps> = ({
  allocation,
  refetch
}) => {
  const dialog = useImperativeDialog()

  const [editedAllocation, setEditedAllocation] = useState<Allocation>(
    allocation
  )

  useEffect(() => {
    setEditedAllocation(allocation)
  }, [allocation])

  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false)

  const [saving, setSaving] = useState<boolean>(false)

  const [message, setMessage] = useState<string>("")

  const {
    active,
    beforeUpdate,
    created_at,
    id,
    items,
    lastReplenishment,
    nextReplenishment,
    updated_at,
  } = editedAllocation

  const handleChangeActive = () => {
    setEditedAllocation({ ...editedAllocation, beforeUpdate, active: !active })
    setEnableSaveButton(true)
  }

  const handleChangeLastReplenishment: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEditedAllocation({
      ...editedAllocation,
      beforeUpdate,
      // @ts-ignore - Casting this value back to a Date breaks the datetime-local picker from working
      lastReplenishment: event.target.value,
    })
    setEnableSaveButton(true)
  }

  const handleChangeNextReplenishment: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEditedAllocation({
      ...editedAllocation,
      beforeUpdate,
      // @ts-ignore - Casting this value back to a Date breaks the datetime-local picker from working
      nextReplenishment: event.target.value,
    })
    setEnableSaveButton(true)
  }

  const handleChangeQuantity = (itemId: number, quantity: number) => {
    const indexOfItemToUpdate = editedAllocation.items.findIndex(
      (item) => item.id === itemId
    )

    items[indexOfItemToUpdate].quantity = quantity

    setEditedAllocation({
      ...editedAllocation,
      beforeUpdate,
      // @ts-ignore - Casting this value back to a Date breaks the datetime-local picker from working
      items: items,
    })
    setEnableSaveButton(true)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    setEnableSaveButton(false)
    setSaving(true)
    setMessage("")
    saveAllocation()
      .then(() => {
        setSaving(false)
        setMessage("Allocation updated!")
      })
      .catch((error) => {
        setEnableSaveButton(true)
        setSaving(false)
        setMessage("Failed to updated allocation!")
        throw error
      })
  }

  const saveAllocation = async () => {
    const payload: Record<string, any> = {
      id: editedAllocation.id,
      active: editedAllocation.active,
      lastReplenishment: editedAllocation.lastReplenishment,
      nextReplenishment: editedAllocation.nextReplenishment,
      items: editedAllocation.items.map((item) => ({
        id: item.id,
        kit_list_item: { quantity: item.kit_list_item.quantity },
        quantity: item.quantity,
      })),
    }
    medusaClient.admin.client.request(
      "POST",
      `/admin/allocations/${id}`,
      payload
    )
  }

  const onReplenishHandler = async () => {
    console.log("replenishing...")
    const shouldReplenish = await dialog({
      heading: "Replenish Allocation",
      text: "Are you sure you want to replenish this allocation?",
    })

    if (!shouldReplenish) return

    const response = await medusaClient.admin.client.request(
      "POST",
      `/admin/allocations/replenish/${allocation.id}`
    )

    await refetch()
    return response.data
  }

  return (
    <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
      <div className="col-span-2 flex justify-end gap-x-2">
        <EditAllocationReplenishButton onClick={onReplenishHandler} />
        <EditAllocationSaveButton disabled={!enableSaveButton} />
      </div>
      <EditAllocationIdField id={id} />
      <EditAllocationCreatedAtField createdAt={created_at} />
      <EditAllocationUpdatedAtField updatedAt={updated_at} />
      <EditAllocationActiveField
        disabled={true}
        active={active}
        handleChangeActive={handleChangeActive}
      />
      <EditAllocationLastReplenishmentField
        handleChangeLastReplenishment={handleChangeLastReplenishment}
        lastReplenishment={lastReplenishment}
      />
      <EditAllocationNextReplenishment
        handleChangeNextReplenishment={handleChangeNextReplenishment}
        nextReplenishment={nextReplenishment}
      />
      <div className="col-span-2">
        <EditAllocationItems
          handleChangeQuantity={handleChangeQuantity}
          items={items}
        />
      </div>
      {saving && <Spinner />}
      {message && <span>{message}</span>}
    </form>
  )
}

export default EditAllocationForm
