import React from "react"
import Select from "../../../../components/molecules/select"
import BodyCard from "../../../../components/organisms/body-card"
import { useAdminArtworkTemplates } from "../../../../hooks/use-admin-artwork-tempalates"
import PdfUpload from "./pdfUpload"
import PrintOptionsProps from "./printOptions.props"

const PrintOptions: React.FC<PrintOptionsProps> = ({
  artworkTemplate,
  onUploadPdfStatusChange,
  productionPdfUrl,
  proofPdfUrl,
  setArtworkTemplate,
  setProductionPdfUrl,
  setProofPdfUrl,
}) => {
  const { artwork_templates } = useAdminArtworkTemplates()

  const artworkTemplatesOptions = artwork_templates?.map((at) => ({
    label: at.name,
    value: at.id,
  }))

  return (
    <BodyCard title="Print Options">
      <div className="mt-large flex gap-y-4">
        <PdfUpload
          name={"Production PDF"}
          onUploadStatusChange={onUploadPdfStatusChange}
          setUrl={setProductionPdfUrl}
          url={productionPdfUrl}
        />
        <PdfUpload
          name={"Proof PDF"}
          onUploadStatusChange={onUploadPdfStatusChange}
          setUrl={setProofPdfUrl}
          url={proofPdfUrl}
        />
      </div>
      <div className="inter-base-semibold text-grey-90 mr-1.5 mt-4">
        <Select
          onChange={(e) => setArtworkTemplate(e)}
          label="Artwork Template"
          name="ArtworkTemplate"
          placeholder="Select Artwork template..."
          options={artworkTemplatesOptions}
          value={artworkTemplate}
          clearSelected
        />
      </div>
    </BodyCard>
  )
}

export default PrintOptions
