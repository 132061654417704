import React from "react"
import { Allocation_item } from "../../../../static/types/medusa-types"
import EditAllocationItemForm from "./edit-allocation-item-form"

interface EditAllocationItemsProps {
  handleChangeQuantity: (itemId: number, quantity: number) => void
  items: Allocation_item[]
}

const EditAllocationItems: React.FC<EditAllocationItemsProps> = ({
  handleChangeQuantity,
  items,
}) => (
  <div>
    <div>Items ({items.length}):</div>
    <div className="grid grid-cols-2 w-full gap-2">
      {items.sort((a, b) => a.id - b.id).map((item) => (
        <EditAllocationItemForm
          handleChangeQuantity={handleChangeQuantity}
          item={item}
        />
      ))}
    </div>
    {/* <EditAllocationAddItem handleAddItem={handleAddItem} /> */}
  </div>
)

export default EditAllocationItems
