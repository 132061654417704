import React, { useMemo } from "react"

const useKitListTableColumn = ({ setTileView, setListView, showList }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row: { original } }) => {
          return <div>{original.name}</div>
        },
      },
      {
        Header: "Replenishment Frequency",
        accessor: "replenishment frequency", // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {`${original.replenishmentFrequencyValue} ${original.replenishmentFrequencyMetric}`}
            </div>
          )
        },
      },
      // {
      //   Header: "Number of customers",
      //   accessor: "Number of customers", // accessor is the "key" in the data
      //   Cell: ({ row: { original } }) => {
      //     return <div>{`${original?.customers?.length}`}</div>
      //   },
      // },
      // {
      //   Header: "Number of Items",
      //   accessor: "Number of Items", // accessor is the "key" in the data
      //   Cell: ({ row: { original } }) => {
      //     console.log("Original number of items...", original)
      //     return <div>{`${original?.items?.length}`}</div>
      //   },
      // },
      {
        accessor: "col-3",
        Header: (
          <div className="text-right flex justify-end">
            {/* <span
              onClick={setListView}
              className={clsx("hover:bg-grey-5 cursor-pointer rounded p-0.5", {
                "text-grey-90": showList,
                "text-grey-40": !showList,
              })}
            >
              <ListIcon size={20} />
            </span> */}
            {/* <span
              onClick={setTileView}
              className={clsx("hover:bg-grey-5 cursor-pointer rounded p-0.5", {
                "text-grey-90": !showList,
                "text-grey-40": showList,
              })}
            >
              <TileIcon size={20} />
            </span> */}
          </div>
        ),
      },
    ],
    [showList]
  )

  return [columns] as const
}

export default useKitListTableColumn
