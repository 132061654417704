import { useAdminAddKitListItem } from "@iq/medusa-react"
import React, { useState } from "react"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import KitListGrid from "../../../../components/kit-list-items-grid"
import BodyCard from "../../../../components/organisms/body-card"
import { useKitListForm } from "../form/kitList-form-context"
import ItemEditor from "../details/items/item-editor"
import { getErrorMessage } from "../../../../utils/error-messages"
import useNotification from "../../../../hooks/use-notification"
import { kitListItemMapper } from "../form/mappers"

const Items = ({ isEdit, kitList }) => {
  const { items, setItems } = useKitListForm()
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const notification = useNotification()
  const addItem = useAdminAddKitListItem(kitList?.id)

  const handleAddItem = (item) => {
    if (isEdit) {
      addItem.mutate(
        {
          name: item.name,
          variant_ids: item.variants.map((n) => n.value),
          quantity: Number.parseInt(item.quantity),
        },
        {
          onSuccess: (kit_list) => {
            console.log("Successfully mutating add item!", kit_list)
            setItems(kitListItemMapper(kit_list.items))
            notification("Success", `item successfully added`, "success")
          },
          onError: (error) => {
            notification("Error", getErrorMessage(error), "error")
          },
        }
      )
      setShowAddItemModal(false)
    } else {
      setItems([...items, item])
      setShowAddItemModal(false)
    }
  }

  return (
    <BodyCard
      title="Kit List Items"
      subtitle="Add items to this kit list."
      forceDropdown={true}
      actionables={[
        {
          label: "Add new kit list item",
          onClick: () => setShowAddItemModal(true),
          icon: <PlusIcon size={20} />,
        },
      ]}
    >
      <KitListGrid
        kitList={kitList}
        edit={isEdit}
        items={items}
        setItems={setItems}
        onItemChange={(vars) => setItems(vars)}
      />
      {showAddItemModal && (
        <ItemEditor
          onCancel={() => setShowAddItemModal(false)}
          onSubmit={(item) => handleAddItem(item)}
          title="Add New Item"
        />
      )}
    </BodyCard>
  )
}

export default Items
