import * as React from "react"
import General from "./sections/general"
import Items from "./sections/items"

type KitListFormProps = {
  kitList?: any
  isEdit?: boolean
}

const KitListForm = ({ kitList, isEdit }: KitListFormProps) => {
  return (
    <>
      <General isEdit={isEdit} kitList={kitList} />
      <Items isEdit={isEdit} kitList={kitList} />
    </>
  )
}

export default KitListForm
