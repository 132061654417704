import { RouteComponentProps, Router } from "@reach/router"
import React, { useContext } from "react"

import BodyCard from "../../../components/organisms/body-card"
import CustomerTable from "../../../components/templates/customer-table"
import CustomerGroups from "../groups"
import CustomerPermissions from "../permissions/index"
import Details from "../details"
import CustomersPageTableHeader from "../header"
import CustomerContext, {
  CustomerContextContainer,
} from "./context/customer-context"
import PlusIcon from "../../../components/fundamentals/icons/plus-icon"

const CustomerIndex = () => {
  const { showModal } = useContext(CustomerContext)
  const actions = [
    {
      label: "New Customer",
      onClick: showModal,
      icon: (
        <span className="text-grey-90">
          <PlusIcon size={20} />
        </span>
      ),
    },
  ]

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          actionables={actions}
          customHeader={<CustomersPageTableHeader activeView="customers" />}
        >
          <CustomerTable />
        </BodyCard>
      </div>
    </div>
  )
}

const Customer: React.FC<RouteComponentProps> = () => {
  return (
    <CustomerContextContainer>
      <CustomerIndex />
    </CustomerContextContainer>
  )
}

const Customers = () => {
  return (
    <Router>
      <Customer path="/" />
      {/* <CustomerGroups path="/groups/*" /> */}
      <CustomerPermissions path="/permissions/*" />
      <Details path=":id" />
    </Router>
  )
}

export default Customers
