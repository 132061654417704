import { navigate } from "gatsby"
import { useAdminCreateKitList } from "@iq/medusa-react"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import { formValuesToCreateKitListMapper } from "../../../domain/kit-lists/kit-list-form/form/mappers"

const useCopyKitList = () => {
  const notification = useNotification()
  const createKitList = useAdminCreateKitList()

  const handleCopyKitList = async (kitList) => {
    const copy = formValuesToCreateKitListMapper(kitList)
    copy.customers = []
    copy.name += " copy"

    try {
      const data = await createKitList.mutateAsync(copy)
      const duplicatedKitList = data?.kit_list
      if (duplicatedKitList) {
        navigate(`/a/kit-lists/${duplicatedKitList.id}`)
        notification("Success", "Duplicated kit list", "success")
      }
    } catch (err) {
      notification("Error", getErrorMessage(err), "error")
    }
  }

  return handleCopyKitList
}

export default useCopyKitList
