import React from "react"
import { Allocation_item } from "../../../../static/types/medusa-types"

interface EditAllocationItemNameFieldProps {
  item: Allocation_item
}

const EditAllocationItemNameField: React.FC<EditAllocationItemNameFieldProps> = ({
  item,
}) => (
  <div className="text-gray-400 items-start bg-grey-5 p-3 flex flex-col border border-grey-20 rounded-rounded">
    <label htmlFor={`item-name-${item.id}`}>Name:</label>
    <input
      type="text"
      className="w-full"
      id={`item-name-${item.id}`}
      name={`item-name-${item.id}`}
      value={item.kit_list_item.name}
    />
  </div>
)

export default EditAllocationItemNameField
