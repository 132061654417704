import { navigate } from "gatsby"
import React from "react"
import TableViewHeader from "../../components/organisms/custom-table-header"

type P = {
  activeView: "customers" | "groups" | "permissions"
}

/*
 * Shared header component for "customers" and "customer groups" page
 */
function CustomersPageTableHeader(props: P) {
  return (
    <TableViewHeader
      setActiveView={(v) => {
        if (v === "customers") {
          navigate(`/a/customers`)
        } else {
          navigate(`/a/customers/permissions`)
        }
      }}
      // views={["customers", "groups"]}
      views={["customers", "permissions"]}
      activeView={props.activeView}
    />
  )
}

export default CustomersPageTableHeader
