import { Router } from "@reach/router"
import React from "react"
import AllocationsTable from "../../components/templates/allocations-table"
import AllocationDetails from "./details"


function Page() {
  return (
    <div className="bg-white py-4 px-6 rounded">
      <AllocationsTable />
    </div>
  )
}

const Allocations = () => {
  return (
    <Router className="h-full">
      <Page path="/" />
      <AllocationDetails path=":id" />
    </Router>
  )
}

export default Allocations
