import React from "react"

interface EditAllocationIdFieldProps {
  id: number
}

const EditAllocationIdField: React.FC<EditAllocationIdFieldProps> = ({
  id,
}) => (
  <div className="text-gray-400 items-start bg-grey-5 p-3 flex flex-col border border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
    <label htmlFor="id">Id:</label>
    <input type="text" id="id" name="id" value={id} disabled />
  </div>
)

export default EditAllocationIdField
