import { Router, navigate, useParams } from "@reach/router"
import React, { useEffect, useState } from "react"
import { usePagination, useTable } from "react-table"
import useApprovalGroupTableColumn from "./approval-group-columns"
import Table, { TablePagination } from "../../components/molecules/table"
import { FilteringOptionProps } from "../../components/molecules/table/filtering-option"
import { useQuery } from "react-query"
import Spinner from "../../components/atoms/spinner"
import useApprovalActions from "./use-approval-actions"
import { medusaClient } from "../../medusa-client"
import Button from "../../components/fundamentals/button"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import { New } from "./new"
import { Edit } from "./edit"

const DEFAULT_PAGE_SIZE = 15

const ApprovalGroupRow = ({ row, refetch }) => {
  const allocation = row.original
  const { getActions } = useApprovalActions(allocation, refetch)

  return (
    <Table.Row
      color={"inherit"}
      linkTo={`/a/approval-groups/${allocation.id}`}
      actions={getActions(allocation)}
      {...row.getRowProps()}
    >
      {" "}
      {row.cells.map((cell, index) => {
        return (
          <Table.Cell {...cell.getCellProps()}>
            {" "}
            {cell.render("Cell", { index })}{" "}
          </Table.Cell>
        )
      })}{" "}
    </Table.Row>
  )
}

function Page() {
  const limit = DEFAULT_PAGE_SIZE
  const [query, setQuery] = useState("")
  const [offset, setOffset] = useState<number>(0)
  const [columns] = useApprovalGroupTableColumn()

  const { data, isLoading, refetch } = useQuery(
    ["fetch_approval_groups", offset, query],
    async () => {
      let url = `/admin/approval-groups?limit=${limit}&offset=${offset}`

      if (query && query != "") {
        url += `&q=${query}`
      }

      const response = await medusaClient.admin.client.request("GET", url)
      return response
    },
    { keepPreviousData: true }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data?.approval_groups || [],
      manualPagination: true,
      initialState: {
        pageIndex: Math.floor(offset / limit),
        pageSize: 8,
      },
      pageCount: Math.ceil(data?.count / limit),
      autoResetPage: false,
    },
    usePagination
  )

  const handleSearch = (q) => {
    setOffset(0)
    setQuery(q)
  }

  const handleNext = () => {
    if (canNextPage) {
      setOffset(offset + limit)
      nextPage()
    }
  }

  const handlePrev = () => {
    if (canPreviousPage) {
      setOffset(offset - limit)
      previousPage()
    }
  }

  const [filteringOptions, setFilteringOptions] = useState<
    FilteringOptionProps[]
  >([])

  return (
    <div className="bg-white p-8">
      <div className="flex justify-between items-center py-4">
        <h1>
          <span className="flex inter-large-semibold gap-x-bas">
            Approval Groups
          </span>
        </h1>
        <Button
          variant="secondary"
          size="small"
          onClick={() => navigate(`/a/approval-groups/new`)}
        >
          <PlusIcon size={20} />
          New Approval Group
        </Button>
      </div>
      <Table
        enableSearch
        handleSearch={handleSearch}
        searchPlaceholder="Search Approval Groups"
        filteringOptions={filteringOptions}
        {...getTableProps()}
      >
        <Table.Head>
          {headerGroups?.map((headerGroup) => (
            <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col) => (
                <Table.HeadCell
                  className="min-w-[100px]"
                  {...col.getHeaderProps()}
                >
                  {col.render("Header")}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        {isLoading || !data ? (
          <div className="w-full pt-2xlarge flex items-center justify-center">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return <ApprovalGroupRow row={row} refetch={refetch} />
            })}
          </Table.Body>
        )}
      </Table>
      <TablePagination
        count={data?.count}
        limit={limit}
        offset={offset}
        pageSize={offset + rows.length}
        title="Approval Groups"
        currentPage={pageIndex + 1}
        pageCount={pageCount}
        nextPage={handleNext}
        prevPage={handlePrev}
        hasNext={canNextPage}
        hasPrev={canPreviousPage}
      />
    </div>
  )
}

const ApprovalGroups = () => {
  return (
    <Router className="h-full">
      <Page path="/" />
      <New path="/new" />
      <Edit path="/:id" />
    </Router>
  )
}

export default ApprovalGroups
