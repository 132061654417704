import * as React from "react"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import { medusaClient } from "../../medusa-client"
import { ActionType } from "../../components/molecules/actionables"
import RefreshIcon from "../../components/fundamentals/icons/refresh-icon"
import { navigate } from "@reach/router"
import EditIcon from "../../components/fundamentals/icons/edit-icon"
import TrashIcon from "../../components/fundamentals/icons/trash-icon"
import { useMutation } from "react-query"

const useApprovalActions = (approval, refetch) => {
  const dialog = useImperativeDialog()
  // const deleteapproval = useAdminDeleteapproval(approval?.id)

  const deleteApproval = useMutation(
    async () => {
      return medusaClient.admin.client.request(
        "DELETE",
        `/admin/approval-groups/${approval.id}`,
        {}
      )
    },
    {
      onSuccess: refetch,
    }
  )

  const handleDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete approval",
      text: "Are you sure you want to delete this approval?",
    })

    if (shouldDelete) {
      deleteApproval.mutate()
    }
  }

  const getActions = (approval): ActionType[] => [
    {
      label: "Edit",
      onClick: () => navigate(`/a/approval-groups/${approval.id}`),
      icon: <EditIcon size={20} />,
    },
    {
      label: "Delete",
      variant: "danger",
      onClick: handleDelete,
      icon: <TrashIcon size={20} />,
    },
  ]

  return {
    getActions,
  }
}

export default useApprovalActions
