import React from "react"
import { Allocation_item } from "../../../../static/types/medusa-types"
import EditAllocationItemNameField from "./edit-allocation-item-name-field"
import EditAllocationItemQuantityField from "./edit-allocation-item-quantity-field"

interface EditAllocationItemFormProps {
  handleChangeQuantity: (itemId: number, quantity: number) => void
  item: Allocation_item
}

const EditAllocationItemForm: React.FC<EditAllocationItemFormProps> = ({
  handleChangeQuantity,
  item,
}) => (
  <div className="bg-grey-5 p-3 flex flex-row border border-grey-20 rounded-rounded">
    <form className="flex flex-col gap-4 w-full">
      <EditAllocationItemNameField item={item} />
      <EditAllocationItemQuantityField
        handleChangeQuantity={handleChangeQuantity}
        item={item}
      />
    </form>
    {/* <div>
      <TrashIcon
        className="cursor-pointer"
        onClick={() => handleDeleteItem(item.id)}
      />
    </div> */}
  </div>
)

export default EditAllocationItemForm
