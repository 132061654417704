import {
  navigate,
  RouteComponentProps,
  Router,
  useLocation,
} from "@reach/router"
import React, { useEffect, useState } from "react"
import Button from "../../components/fundamentals/button"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import BodyCard from "../../components/organisms/body-card"
import TableViewHeader from "../../components/organisms/custom-table-header"
import KitListTable from "../../components/templates/kit-list-table"
import EditKitListPage from "./edit"
import KitListitemPage from "./items-details"
import NewKitListPage from "./new"

const VIEWS = ["kit-lists"]
const KitListIndex: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const [view, setView] = useState("kit-lists")

  useEffect(() => {
    if (location.search.includes("?view=kit-lists")) {
      setView("kit-lists")
    }
  }, [location])

  useEffect(() => {
    // location.search = ""
  }, [view])

  const CurrentView = () => {
    switch (view) {
      case "kit-lists":
        return <KitListTable />
      default:
        return <></>
    }
  }

  const CurrentAction = () => {
    switch (view) {
      case "kit-lists":
        return (
          <div className="flex space-x-2">
            <Button
              variant="secondary"
              size="small"
              onClick={() => navigate(`/a/kit-lists/new`)}
            >
              <PlusIcon size={20} />
              New Kit List
            </Button>
          </div>
        )

      default: {
        return <></>
      }
    }
  }

  return (
    <>
      <div className="flex flex-col grow h-full">
        <div className="w-full flex flex-col grow">
          <BodyCard
            forceDropdown={false}
            customActionable={CurrentAction()}
            customHeader={
              <TableViewHeader
                views={VIEWS}
                setActiveView={(view) => {
                  if (view === "kit-lists") {
                    navigate(`/a/kit-lists`)
                  } else if (view === "allocations") {
                    navigate(`/a/allocations`)
                  }
                }}
                activeView={view}
              />
            }
          >
            <CurrentView />
          </BodyCard>
        </div>
      </div>
      {/* {showNewCollection && (
        <AddCollectionModal
          onClose={() => setShowNewCollection(!showNewCollection)}
          onSubmit={handleCreateCollection}
        />
      )}
      {exportModalOpen && (
        <ExportModal
          title="Export Products"
          handleClose={() => closeExportModal()}
          onSubmit={handleCreateExport}
          loading={createBatchJob.isLoading}
        />
      )} */}
    </>
  )
}

const KitLists = () => {
  return (
    <Router>
      <KitListIndex path="/" />
      <EditKitListPage path=":id" />
      <NewKitListPage path="new" />
      <KitListitemPage path="/:id/kitListItem/:itemId" />
    </Router>
  )
}

export default KitLists
