import React from "react"
import { ApprovalGroupForm, OnApprovalGroupSubmit } from "./form"
import { ApprovalGroup } from "./types"
import { navigate } from "@reach/router"
import { medusaClient } from "../../medusa-client"
import useNotification from "../../hooks/use-notification"

export function New() {
  const notification = useNotification()
  const onSubmit = async (data: OnApprovalGroupSubmit) => {
    const result = await medusaClient.admin.client
      .request("POST", `/admin/approval-groups`, data)
      .then(() => {
        notification("Success", "Created Approval Group", "success")
      })
      .catch((err) => {
        console.error(err)
        notification("Error", "Failed to create Approval Group", "error")
      })

    console.log("create result ->", result)
  }

  return (
    <ApprovalGroupForm
      title="New Approval Group"
      onSubmit={onSubmit}
      onBack={() => {
        navigate("/a/approval-groups")
      }}
      actionText={"Create"}
    />
  )
}
