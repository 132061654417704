import { navigate } from "gatsby"
import * as React from "react"
import useImperativeDialog from "../../../hooks/use-imperative-dialog"
import { medusaClient } from "../../../medusa-client"
import EditIcon from "../../fundamentals/icons/edit-icon"
import RefreshIcon from "../../fundamentals/icons/refresh-icon"
import TrashIcon from "../../fundamentals/icons/trash-icon"
import { ActionType } from "../../molecules/actionables"

const useAllocationActions = (allocation) => {
  const dialog = useImperativeDialog()
  // const deleteAllocation = useAdminDeleteAllocation(allocation?.id)

  const handleDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete Allocation",
      text: "Are you sure you want to delete this allocation?",
    })

    if (shouldDelete) {
      // deleteAllocation.mutate()
    }
  }

  const onReplenish = async () => {
    const shouldReplenish = await dialog({
      heading: "Replenish Allocation",
      text: "Are you sure you want to replenish this allocation?",
    })

    if (!shouldReplenish) return

    const response = await medusaClient.admin.client.request(
      "POST",
      `/admin/allocations/replenish/${allocation.id}`
    )

    return response.data
  }

  const getActions = (allocation): ActionType[] => [
    {
      label: "Replenish",
      icon: <RefreshIcon size={20} />,
      onClick: onReplenish
    },
    {
      label: "Edit",
      onClick: () => navigate(`/a/allocations/${allocation.id}`),
      icon: <EditIcon size={20} />,
    },
    // {
    //   label: "Delete",
    //   variant: "danger",
    //   onClick: handleDelete,
    //   icon: <TrashIcon size={20} />,
    // },
  ]

  return {
    getActions,
  }
}

export default useAllocationActions
