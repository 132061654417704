import { Router } from "@reach/router"
import React, { useEffect, useMemo, useState } from "react"
import Select, { GroupHeadingProps, components } from "react-select"
import AsyncSelect from "react-select/async"
import { ProductVariant } from "@medusajs/medusa"
import { useQuery } from "react-query"
import { medusaClient } from "../../medusa-client"
import _ from "lodash"
import XCircleIcon from "../../components/fundamentals/icons/x-circle-icon"
import InputField from "../../components/molecules/input"
import Tooltip from "../../components/atoms/tooltip"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import Button from "../../components/fundamentals/button"
import CheckCircleIcon from "../../components/fundamentals/icons/check-circle-icon"

const primaryColor = "#7c3aed"

const lighten = (amount, color) => {
  // Convert hex to RGB values
  const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16))
  // Apply lighten algorithm
  const lightenChannel = (channel) =>
    Math.round(channel + (255 - channel) * amount)
  const [lightenedR, lightenedG, lightenedB] = [r, g, b].map(lightenChannel)
  // Convert RGB back to hex
  const lightenColor = `#${((lightenedR << 16) | (lightenedG << 8) | lightenedB)
    .toString(16)
    .padStart(6, "0")}`
  return lightenColor
}

function convertVariantsToGroupedOptions(variants: ProductVariant[]) {
  const groupedByTitle = variants.reduce((acc, curr) => {
    const key = curr.product!.title

    const value = {
      label: curr.title,
      value: curr,
    }

    if (Array.isArray(acc[key])) {
      acc[key].push(value)
    } else {
      acc[key] = [value]
    }

    return acc
  }, {})

  console.log("Grouped by title:", groupedByTitle)

  const groupedOptions = Object.entries(groupedByTitle)
    .map(([key, value]) => {
      return {
        label: key,
        options: value,
      }
    })
    .flat()

  return groupedOptions
}

type BaseGroupHeadingProps = React.PropsWithChildren<
  GroupHeadingProps<
    {
      label: string
      value: string
    },
    true,
    any
  >
>
const GroupHeading = ({
  children,
  selectedOptions,
  setSelectedOptions,
  isExpanded,
  setIsExpanded,
  ...props
}: BaseGroupHeadingProps & {
  selectedOptions: Array<{ label: string; value: string }>
  setSelectedOptions: (value: any) => void
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
}) => {
  const { selectCount, hasSelectedAll, isPartiallySelected } = useMemo(() => {
    const o1 = props.data.options
    const o2 = selectedOptions

    let hasSelectedAll = false
    let selectCount = 0
    for (let i = 0; i < o1.length; i++) {
      if (o2.findIndex((x) => x.label === o1[i].label) === -1) {
        hasSelectedAll = false
      } else {
        hasSelectedAll = true
        selectCount++
      }
    }

    return {
      hasSelectedAll,
      selectCount,
      isPartiallySelected: selectCount > 0 && selectCount < o1.length,
    }
  }, [])

  const onClickHandler = () => {
    if (!hasSelectedAll) {
      setSelectedOptions([...selectedOptions, ...props.data.options])
    } else {
      setSelectedOptions(
        selectedOptions.filter((x) => {
          return props.data.options.findIndex((y) => y.label === x.label) === -1
        })
      )
    }
  }

  return (
    <components.GroupHeading
      {...props}
      className={`${props.className}`}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 4,
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <input
        type="checkbox"
        className={`h-4 w-4 accent-purple-600`}
        checked={hasSelectedAll}
        onChange={onClickHandler}
      />
      <button onClick={onClickHandler}>
        <span className="text-base ">
          {children}{" "}
          <span className="text-purple-400 text-sm">{`(${selectCount})`}</span>
        </span>
      </button>

      <button
        className="border rounded px-2"
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <span className="text-xs">
          {isExpanded ? "Hide Variants" : "Show Variants"}
        </span>
      </button>
    </components.GroupHeading>
  )
}

function SelectGroup(props: {
  placeholder: string
  name: string
  label: string
  selectedOptions: Array<{ label: string; value: any }>
  setSelectedOptions: (value: any) => void
  options: Array<{ label: string; value: string }>
  loadOptions: (inputValue: string) => Promise<any[]>
}) {
  const [showSelected, setShowSelected] = React.useState(true)
  const [expandedGroups, setExpandedGroups] = React.useState<string[]>([])
  return (
    <div className="space-y-4 pt-4">
      <span className="inter-base-semibold">{props.label}</span>
      <div>
        <AsyncSelect
          menuPlacement="auto"
          maxMenuHeight={300}
          defaultOptions
          isMulti={true}
          value={props.selectedOptions}
          name={props.name}
          controlShouldRenderValue={true}
          isClearable
          hideSelectedOptions={!showSelected}
          placeholder={props.placeholder || "Select..."}
          closeMenuOnSelect={false}
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              minHeight: 64,
              maxHeight: 64,
              overflowY: "auto"
            }),
            option: (provided, state) => ({
              ...provided,
            }),
          }}
          options={props.options}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            // Option: () => null,
            Group: ({ children, ...p }) => {
              return (
                <components.Group
                  {...p}
                  className="p-0"
                  Heading={(h) => (
                    <GroupHeading
                      selectedOptions={props.selectedOptions}
                      setSelectedOptions={props.setSelectedOptions}
                      isExpanded={expandedGroups.includes(p.data.label)}
                      setIsExpanded={(value) => {
                        if (value) {
                          setExpandedGroups([
                            ...expandedGroups,
                            p.data.label as string,
                          ])
                        } else {
                          setExpandedGroups(
                            expandedGroups.filter((x) => x !== p.data.label)
                          )
                        }
                      }}
                      {...h}
                    />
                  )}
                >
                  {expandedGroups.includes(p.data.label) ? (
                    <div className="bg-gray-100">{children}</div>
                  ) : (
                    <></>
                  )}
                </components.Group>
              )
            },
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: primaryColor,
              primary25: lighten(0.8, primaryColor),
              primary50: lighten(0.65, primaryColor),
              primary75: lighten(0.55, primaryColor),
            },
          })}
          loadOptions={props.loadOptions}
          onChange={(value) => {
            props.setSelectedOptions(value as any)
          }}
        />

        <div className="flex space-x-2 pb-2">
          <button onClick={() => setShowSelected(!showSelected)}>
            <span className="text-xs text-gray-600">
              {showSelected ? "Hide selected" : "Show selected"}
            </span>
          </button>
          <button
            onClick={() => {
              props.setSelectedOptions([])
            }}
          >
            <span className="text-xs text-gray-600">Clear selected</span>
          </button>
        </div>
      </div>
      {/* <div
        className="flex flex-wrap gap-2 bg-gray-100 p-2 rounded"
        style={{
          minHeight: 60,
          maxHeight: 240,
          overflowY: "auto",
        }}
      >
        {props.selectedOptions?.map((c) => {
          return (
            <div className="flex items-center justify-between border p-2 rounded-lg space-x-2 bg-white text-xs">
              <span className="">{c.label}</span>
              <button
                onClick={() => {
                  props.setSelectedOptions(
                    props.selectedOptions.filter((s) => s.value !== c.value)
                  )
                }}
              >
                <XCircleIcon color="#9CA3AF" className="mr-2" />
              </button>
            </div>
          )
        })}
      </div> */}
    </div>
  )
}

function DifferencePreview({
  property,
  previousValue,
  newValue,
}: {
  property: string
  previousValue: string
  newValue?: string
}) {
  if (!newValue || previousValue === newValue) {
    return <></>
  }

  return (
    <div className="flex items-center space-x-2 w-full">
      <span className="text-gray-800 text-xs border-r pr-1 w-32 text-center py-1 bg-gray-50">
        {property}
      </span>
      <div className="flex gap-x-1 items-center w-full overflow-hidden">
        <span className="text-green-600 text-xs">
          {" "}
          <Tooltip content={newValue}>{newValue}</Tooltip>
        </span>
        <span className="text-gray-500 line-through truncate overflow-hidden text-xs">
          <Tooltip content={previousValue}>{previousValue}</Tooltip>
        </span>
        {/* <ArrowRightIcon /> */}
      </div>
    </div>
  )
}
function SelectedProductPreview({
  label,
  value,
  removeSelectedVariants,
  onUpdate,
  newValues,
}: {
  label: string
  value: any[]
  removeSelectedVariants: (variants: ProductVariant[]) => void
  onUpdate: (variant: ProductVariant) => Promise<boolean>
  newValues: NewValues
}) {
  return (
    <ul className="grid grid-cols-2 gap-2 pt-2">
      {value.map((x) => {
        return (
          <li className="flex items-center space-x-2 border rounded">
            <div
              className="flex flex-col justify-start flex-1 "
              style={{
                minWidth: 300,
              }}
            >
              <div className="flex items-center space-x-2 justify-start border-b bg-gray-100 pl-2 h-full rounded">
                <h2 className="font-bold text-xs py-1">{x.label}</h2>
                <div className="flex-1 justify-end flex h-full pr-1 gap-x-2 my-1">
                  <button
                    className="border border-gray-300 rounded text-xs flex justify-center items-center"
                    onClick={() => {
                      onUpdate(x.value)
                    }}
                  >
                    <span className="p-1 w-12 flex justify-center">
                      <span className="px-1.5">Update</span>
                    </span>
                  </button>
                  {/* <button
                    className="border border-gray-300 rounded text-xs flex justify-center items-center px-1"
                    onClick={() => {
                      removeSelectedVariants([x.value])
                    }}
                  >
                    <span className="px-1.5">x</span>
                  </button> */}
                </div>
              </div>
              <div className="divide-y bg-white">
                <DifferencePreview
                  property={"Supplier"}
                  newValue={newValues.supplier?.name}
                  // newValue="ApproachPS"
                  previousValue={x.value.supplier?.name}
                />
                <DifferencePreview
                  property={"Subcategory Code"}
                  newValue={newValues.subcategoryCode}
                  previousValue={x.value.subcategoryCode}
                />
                <DifferencePreview
                  property={"Quantity"}
                  newValue={newValues.quantity}
                  previousValue={x.value.inventory_quantity.toString()}
                />
                <DifferencePreview
                  property={"Cost Price"}
                  newValue={
                    newValues.costPrice &&
                    "£" + (+newValues.costPrice).toFixed(2).toString()
                  }
                  previousValue={"£" + (x.value.costPrice / 100).toString()}
                />
                <DifferencePreview
                  property={"Price"}
                  newValue={
                    newValues.price &&
                    "£" + (+newValues.price).toFixed(2).toString()
                  }
                  previousValue={
                    "£" + (x.value.prices[0].amount / 100).toFixed(2).toString()
                  }
                />
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

interface NewValues {
  subcategoryCode?: string
  costPrice?: string
  sku?: string
  supplierSKU: string
  price?: string
  quantity?: string
  supplier?: any
}
function Page() {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const dialog = useImperativeDialog()

  const { data, refetch: refetchVariants } = useQuery(
    "variants",
    () => {
      return medusaClient.admin.variants.list({
        limit: 50,
      })
    },
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  )

  const { data: supplierData } = useQuery(
    "supplier",
    () => {
      return medusaClient.admin.client.request("GET", "/admin/supplier")
    },
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  )

  console.log("supplier data:", supplierData)

  const suppliers = supplierData?.suppliers
  const variants = data?.variants

  const supplierOptions = useMemo(() => {
    if (!suppliers) return []
    return suppliers.map((x) => {
      return {
        label: x.name,
        value: x,
      }
    })
  }, [suppliers])

  const variantOptions = useMemo(() => {
    if (!variants) return []
    const groupedOptions = convertVariantsToGroupedOptions(variants)
    return groupedOptions as any[]
  }, [variants])

  const [selectedVariants, setSelectedVariants] = useState<
    { label: string; value: ProductVariant }[]
  >([])

  const [newValues, setNewValues] = useState<NewValues>({})

  const selectedValuesGroupedByProduct = useMemo(() => {
    const groupedByProduct = selectedVariants.reduce((acc, curr) => {
      const key = curr.value.product!.title

      console.log(key)

      if (Array.isArray(acc[key])) {
        acc[key].push(curr)
      } else {
        acc[key] = [curr]
      }

      return acc
    }, {})

    return groupedByProduct
  }, [selectedVariants])
  console.log(selectedValuesGroupedByProduct)

  const removeSelectedVariants = (variants: ProductVariant[]) => {
    const idsToRemove = variants.map((x) => x.id)
    const newSelectedVariants = selectedVariants.filter((x) => {
      return !idsToRemove.includes(x.value.id)
    })
    setSelectedVariants(newSelectedVariants)
  }

  const convertNewValuesToPayload = () => {
    const {
      subcategoryCode,
      supplierSKU,
      costPrice,
      price,
      quantity,
      sku,
      supplier,
    } = newValues

    const body: {
      costPrice?: number
      sku?: string
      supplierSKU?: string
      price?: number
      inventory_quantity?: number
      supplier_id?: string
      subcategoryCode?: string
    } = {}

    if (supplierSKU && supplierSKU !== "") {
      body.supplierSKU = newValues.supplierSKU
    }

    if (subcategoryCode && subcategoryCode !== "") {
      body.subcategoryCode = subcategoryCode
    }

    if (supplier) {
      body.supplier_id = supplier.id
    }

    if (costPrice && costPrice !== "") {
      body.costPrice = Math.round(+costPrice * 100)
    }

    if (price && price !== "") {
      body.price = Math.round(+price * 100)
    }

    if (quantity && quantity !== "") {
      body.inventory_quantity = +quantity
    }

    if (sku && sku !== "") {
      body.sku = sku
    }

    return body
  }

  const updateBody = convertNewValuesToPayload()

  const updateVariant = async (variant: ProductVariant) => {
    console.log("Updating variant", {
      variant,
      newValues,
    })

    return medusaClient.admin.client.request(
      "POST",
      `/admin/variant/partial/${variant.id}`,
      updateBody
    )
  }

  const onUpdateAllHandler = async () => {
    const shouldContinue = await dialog({
      heading: "Update all variants",
      text: "Are you sure you want to update all variants?",
    })

    if (!shouldContinue) {
      return false
    }

    setIsSaving(true)
    Promise.all(selectedVariants.map(({ value }) => updateVariant(value)))
      .catch(() => {
        setIsSuccess(false)
      })
      .then(() => {
        setIsSaving(false)
        setSelectedVariants([])
        setIsSuccess(true)
        refetchVariants()
      })
  }

  const onUpdateHandler = async (variant: ProductVariant) => {
    const shouldContinue = await dialog({
      heading: "Update variant",
      text: "Are you sure you want to update this variant?",
    })

    if (!shouldContinue) {
      return false
    }

    await updateVariant(variant)

    setSelectedVariants(
      selectedVariants.filter((x) => x.value.id !== variant.id)
    )

    await refetchVariants()

    return true
  }

  const loadOptions = useMemo(
    () => async (inputValue) => {
      const response = await medusaClient.admin.variants.list({
        q: inputValue,
        limit: 50,
        offset: 0,
      })
      return convertVariantsToGroupedOptions(response.variants)
    },
    [variantOptions]
  )

  useEffect(() => {
    setIsSuccess(null)
  }, [selectedVariants])

  return (
    <div className="bg-white rounded border">
      <div className=" p-4 rounded-t border-b">
        <SelectGroup
          setSelectedOptions={setSelectedVariants}
          selectedOptions={selectedVariants}
          options={variantOptions}
          placeholder="Search for a product"
          name="variants"
          label="Select Products To Update"
          loadOptions={loadOptions}
        />
      </div>
      <div className="grid grid-cols-3 gap-x-4">
        <div className="px-4 pt-8 pb-8 border-r">
          <h1 className="font-semibold pb-2">Values</h1>
          <div className="flex flex-col space-y-2">
            <div className="space-y-2">
              <label htmlFor="supplier">Supplier</label>
              <div>
                <AsyncSelect
                  id="supplier"
                  options={supplierOptions}
                  value={{
                    label: newValues.supplier?.name,
                    value: newValues.supplier,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: primaryColor,
                      primary25: lighten(0.8, primaryColor),
                      primary50: lighten(0.65, primaryColor),
                      primary75: lighten(0.55, primaryColor),
                    },
                  })}
                  defaultOptions
                  onChange={(value) => {
                    setNewValues({
                      ...newValues,
                      supplier: value?.value || null,
                    })
                  }}
                  loadOptions={async (inputValue) => {
                    const result = await medusaClient.admin.client.request(
                      "GET",
                      `/admin/supplier?q=${inputValue}`
                    )

                    return result.suppliers.map((x) => {
                      return {
                        label: x.name,
                        value: x,
                      }
                    })
                  }}
                />
                <button
                  className="text-xs text-gray-400"
                  onClick={() => {
                    setNewValues({
                      ...newValues,
                      supplier: undefined,
                    })
                  }}
                >
                  <span>Reset</span>
                </button>
              </div>
            </div>
            <div>
              <InputField
                label="Subcategory Code"
                type="text"
                value={newValues.subcategoryCode || ""}
                onChange={(e) => {
                  setNewValues({
                    ...newValues,
                    subcategoryCode: e.target.value,
                  })
                }}
              />
              <button
                className="text-xs text-gray-400"
                onClick={() => {
                  setNewValues({
                    ...newValues,
                    subcategoryCode: undefined,
                  })
                }}
              >
                <span>Reset</span>
              </button>
            </div>
            <div>
              <InputField
                label="Quantity"
                type="number"
                value={newValues.quantity || ""}
                onChange={(e) => {
                  setNewValues({
                    ...newValues,
                    quantity: Math.max(0, +e.target.value).toString(),
                  })
                }}
              />
              <button
                className="text-xs text-gray-400"
                onClick={() => {
                  setNewValues({
                    ...newValues,
                    quantity: undefined,
                  })
                }}
              >
                <span>Reset</span>
              </button>
            </div>
            <div>
              <InputField
                label="Price"
                type="number"
                startAdornment="£"
                value={newValues.price || ""}
                onChange={(e) => {
                  setNewValues({
                    ...newValues,
                    price: Math.max(0, +e.target.value).toString(),
                  })
                }}
              />
              <button
                className="text-xs text-gray-400"
                onClick={() => {
                  setNewValues({
                    ...newValues,
                    price: undefined,
                  })
                }}
              >
                <span>Reset</span>
              </button>
            </div>
            <div>
              <InputField
                label="Cost Price"
                type="number"
                startAdornment="£"
                value={newValues.costPrice || ""}
                onChange={(e) => {
                  setNewValues({
                    ...newValues,
                    costPrice: Math.max(0, +e.target.value).toString(),
                  })
                }}
              />
              <button
                className="text-xs text-gray-400"
                onClick={() => {
                  setNewValues({
                    ...newValues,
                    costPrice: undefined,
                  })
                }}
              >
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 col-span-2 px-4 pt-8 pb-8">
          <div className="flex justify-between ">
            <h1 className="font-semibold">Preview Changes</h1>
            <Button
              variant="primary"
              disabled={selectedVariants.length === 0}
              loading={isSaving}
              size="small"
              onClick={() => {
                onUpdateAllHandler()
              }}
            >
              Update all
            </Button>
          </div>
          <div className="space-y-2 overflow-y-auto bg-gray-50 h-full p-2 max-h-full">
            {selectedVariants.length === 0 ? (
              isSuccess ? (
                <div className="flex items-center justify-center h-full gap-x-1">
                  <CheckCircleIcon className="text-green-600" size="18" />
                  <span className="text-green-600 text-sm">Success</span>
                </div>
              ) : isSuccess === false ? (
                <div className="flex items-center justify-center h-full gap-x-1">
                  <XCircleIcon className="text-red-600" size="18" />
                  <span className="text-red-600 text-sm">Failed to update</span>
                </div>
              ) : (
                <div className="flex items-center justify-center h-full gap-x-1">
                  <span className="text-gray-400 text-sm">
                    No variants selected
                  </span>
                </div>
              )
            ) : (
              <></>
            )}
            {Object.entries(selectedValuesGroupedByProduct).map((kv) => {
              const [key, value] = kv as [string, any[]]
              return (
                <div>
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-gray-600 rounded px-3 text-xs text-white">
                        {key}
                      </span>
                    </div>
                  </div>
                  <SelectedProductPreview
                    label={key}
                    value={value}
                    removeSelectedVariants={removeSelectedVariants}
                    onUpdate={onUpdateHandler}
                    newValues={newValues}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const Data = () => {
  return (
    <Router className="h-full">
      <Page path="/" />
    </Router>
  )
}

export default Data
