import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from "react"
import { getErrorMessage } from "../../../../utils/error-messages"
import useNotification from "../../../../hooks/use-notification"
import { CustomerPermissions } from "../../../../../static/types/medusa-types"
import CustomerPermissionsModal from "../customer-permissions-modal"
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query"
import { medusaClient, queryClient } from "../../../../medusa-client"
import { useCustomerFilters } from "../../../../components/templates/customer-permissions-table/use-customer-filters"
import qs from "qs"
import { useToaster } from "react-hot-toast"

type CustomerPermissionsContextT = {
  permissions?: CustomerPermissions
  showModal: () => void
  hideModal: () => void
  reset: () => void
  paginate: (direction: 1 | -1) => void
  setQuery: (queryString: string | null) => void
  queryObject: any
  representationObject: any
  deleteCustomerPermission: UseMutateAsyncFunction<
    any,
    unknown,
    number,
    unknown
  >
  updatePermissions: UseMutateAsyncFunction<any, unknown, number, unknown>
  data: any
  isLoading: boolean
  refetch: () => void
}

const CustomerPermissionsContext = createContext<CustomerPermissionsContextT>(
  {} as any
)

type CustomerPermissionsContextContainerT = PropsWithChildren<{
  permissions?: CustomerPermissions
}>

const defaultQueryProps = {
  expand: "orders",
}
/*
 * A context provider which sets a display mode for `CustomerPermissionsModal` (create/edit)
 * and provide form data inside the context.
 */
export function CustomerPermissionsContextContainer(
  props: CustomerPermissionsContextContainerT
) {
  const notification = useNotification()

  const {
    reset,
    paginate,
    setQuery: setFreeText,
    queryObject,
    representationObject,
  } = useCustomerFilters(location.search, defaultQueryProps)

  const permissionsResponse = useQuery(
    ["customer-permissions", queryObject],
    async () => {
      const response = await medusaClient.admin.client.request(
        "GET",
        `/admin/customer-permissions?${qs.stringify(queryObject)}`
      )

      return response
    }
  )

  const { mutateAsync: deleteCustomerPermission } = useMutation(
    "delete-customer-permission",
    async (id: number) => {
      try {
        const response = await medusaClient.admin.client.request(
          "DELETE",
          `/admin/customer-permissions/${id}`,
          {}
        )
        await permissionsResponse.refetch()
        return response
      } catch (error) {
        const e: any = error
        let message = "Error deleting customer permission"

        if (e?.response?.data?.message) {
          message = e.response.data.message
        }

        notification("Error", message, "error")
        return null
      }
    }
  )

  const isLoading = permissionsResponse?.isLoading
  const data = permissionsResponse?.data || {}
  const refetch = permissionsResponse?.refetch

  const { mutate: createPermissions } = useMutation(
    async (payload: Partial<CustomerPermissions>) => {
      const result = await medusaClient.admin.client.request(
        "POST",
        "/admin/customer-permissions",
        payload
      )

      refetch()

      return result
    }
  )

  const { mutate: updatePermissions } = useMutation(
    async (payload: { id: string; data: Partial<CustomerPermissions> }) => {
      return null
    }
  )

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const hideModal = () => setIsModalVisible(false)

  const handleSubmit = async (data) => {
    const isEdit = !!props.permissions || data?.id != null

    const message = `Successfully ${
      isEdit ? "edited" : "created"
    } the permission`

    const handlers = {
      onSuccess: () => {
        notification("Success", message, "success")
        hideModal()
      },
      onError: (err) => {
        notification("Error", getErrorMessage(err), "error")
        hideModal()
      },
    }

    if (isEdit) {
      updatePermissions(data, handlers)
    } else {
      createPermissions(data, handlers)
    }
  }

  const context = {
    // group: props.group,
    permissions: props.permissions,
    isModalVisible,
    showModal,
    hideModal,
    reset,
    paginate,
    setQuery: setFreeText,
    queryObject,
    representationObject,
    deleteCustomerPermission,
    updatePermissions,
    data,
    isLoading,
    refetch,
  }

  return (
    <CustomerPermissionsContext.Provider value={context}>
      {props.children}

      {isModalVisible && (
        <CustomerPermissionsModal
          handleClose={hideModal}
          handleSubmit={handleSubmit}
          initialData={props.permissions}
        />
      )}
    </CustomerPermissionsContext.Provider>
  )
}

export default CustomerPermissionsContext
