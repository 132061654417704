import { RouteComponentProps } from "@reach/router"
import { useAdminCustomer } from "@iq/medusa-react"
import moment from "moment"
import React, { useState } from "react"
import Avatar from "../../../components/atoms/avatar"
import Spinner from "../../../components/atoms/spinner"
import EditIcon from "../../../components/fundamentals/icons/edit-icon"
import TrashIcon from "../../../components/fundamentals/icons/trash-icon"
import StatusDot from "../../../components/fundamentals/status-indicator"
import Actionables from "../../../components/molecules/actionables"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import BodyCard from "../../../components/organisms/body-card"
import RawJSON from "../../../components/organisms/raw-json"
import CustomerOrdersTable from "../../../components/templates/customer-orders-table"
import EditCustomerModal from "./edit"
import { Link, navigate } from "gatsby"
import ChildGrid from "../../../components/base/child-grid"
import { Customer } from "../../../../static/types/medusa-types"

type CustomerDetailProps = {
  id: string
} & RouteComponentProps

const CustomerDetail: React.FC<CustomerDetailProps> = ({ id }) => {
  const { isLoading: isLoadingCustomer, ...adminData } = useAdminCustomer(
    id,
    {}
  )

  const [showEdit, setShowEdit] = useState(false)

  if (isLoadingCustomer) {
    return <Spinner />
  }

  const customer = adminData.customer as Customer

  const customerName = () => {
    if (customer?.first_name || customer?.last_name) {
      return `${customer?.first_name ?? ""} ${customer?.last_name ?? ""}`.trim()
    } else {
      return customer?.email
    }
  }

  const activeAllocation = customer?.allocations?.find((f) => f.active)

  const actions = [
    {
      label: "Edit",
      onClick: () => setShowEdit(true),
      icon: <EditIcon size={20} />,
    },
    {
      label: "Delete (not implemented yet)",
      onClick: () => console.log("TODO: delete customer"),
      variant: "danger",
      icon: <TrashIcon size={20} />,
    },
  ]

  return (
    <div>
      <Breadcrumb
        currentPage={"Customer Details"}
        previousBreadcrumb={"Customers"}
        previousRoute="/a/customers"
      />
      <BodyCard className={"h-auto w-full mb-4"}>
        <div className="w-full absolute top-0 right-0 left-0 bg-gradient-to-b from-fuschia-20 z-0" />
        <div className="flex flex-col grow overflow-y-auto">
          <div className="w-[64px] h-[64px] mb-4">
            <Avatar
              user={customer}
              font="inter-2xlarge-semibold"
              color="bg-fuschia-40"
            />
          </div>
          <div className="flex items-center justify-between">
            <h1 className="inter-xlarge-semibold text-grey-90 truncate max-w-[50%]">
              {customerName()}
            </h1>
            <Actionables actions={actions} />
          </div>
          <h3 className="inter-small-regular pt-1.5 text-grey-50">
            {customer?.email}
          </h3>
        </div>
        <div className="flex mt-6 space-x-6 divide-x">
          <div className="flex flex-col">
            <div className="inter-smaller-regular text-grey-50 mb-1">
              First seen
            </div>
            <div>{moment(customer?.created_at).format("DD MMM YYYY")}</div>
          </div>
          <div className="flex flex-col pl-6">
            <div className="inter-smaller-regular text-grey-50 mb-1">Phone</div>
            <div className="truncate max-w-[200px]">
              {customer?.phone || "N/A"}
            </div>
          </div>
          <div className="flex flex-col pl-6">
            <div className="inter-smaller-regular text-grey-50 mb-1">
              Permissions
            </div>
            <div className="truncate max-w-[200px]">
              <Link
                to={`/a/customers/permissions/${
                  // @ts-ignore
                  customer?.permissions?.id
                }`}
              >
                <span>
                  {
                    // @ts-ignore
                    customer?.permissions?.name || "N/A"
                  }
                </span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col pl-6">
            <div className="inter-smaller-regular text-grey-50 mb-1">
              Orders
            </div>
            <div>{customer?.orders.length}</div>
          </div>
          <div className="flex flex-col pl-6 h-100">
            <div className="inter-smaller-regular text-grey-50 mb-1">
              Blocked
            </div>
            <div className="flex justify-center items-center h-50">
              <StatusDot
                variant={!customer?.is_blocked ? "success" : "danger"}
                title={customer?.is_blocked ? "True" : "False"}
              />
            </div>
          </div>
        </div>
      </BodyCard>
      <BodyCard
        className={"h-auto w-full mb-4"}
        title={`Access Log`}
        subtitle="Log of customer login times"
      >
        {isLoadingCustomer || !customer ? (
          <div className="w-full pt-2xlarge flex items-center justify-center">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        ) : (
          <div>
            <h2>Succesful Logins:</h2>
            {customer?.successful_logins.map((successfulLogin) => (
              <span key={successfulLogin}>
                {new Date(+successfulLogin).toString()}
              </span>
            ))}
          </div>
        )}
      </BodyCard>
      <BodyCard
        className={"h-auto w-full mb-4"}
        title={`General`}
        subtitle="An overview of the Customer"
      >
        {isLoadingCustomer || !customer ? (
          <div className="w-full pt-2xlarge flex items-center justify-center">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-3">
              <div className="inter-base-semibold regular mb-1">
                Cost Centres
                <ul>
                  <li className="inter-base-regular">
                    {customer?.branch?.identifier}
                  </li>
                </ul>
              </div>

              <div className="inter-base-semibold regular mb-1">
                Groups
                <ul>
                  {customer?.groups.map((m) => {
                    return (
                      <li key={m.name} className="inter-base-regular">
                        {m.name}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </>
        )}
      </BodyCard>
      <BodyCard
        className={"h-auto w-full mb-4"}
        title={`Orders (${customer?.orders.length})`}
        subtitle="An overview of Customer Orders"
      >
        {isLoadingCustomer || !customer ? (
          <div className="w-full pt-2xlarge flex items-center justify-center">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        ) : (
          <div className="flex grow  flex-col pt-2 mt-large">
            <CustomerOrdersTable customerId={customer.id} />
          </div>
        )}
      </BodyCard>

      <BodyCard
        className={"h-auto w-full mb-4"}
        title={`Allocation`}
        subtitle="An overview of the Customers active allocation"
      >
        {isLoadingCustomer || !customer ? (
          <div className="w-full pt-2xlarge flex items-center justify-center">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        ) : (
          <div>
            {activeAllocation && (
              <div>
                <div className="grid grid-cols-3 pt-2 ">
                  <div className="inter-base-semibold regular mb-1">
                    Allocated kitList:{" "}
                    <button
                      onClick={() =>
                        navigate(`/a/kit-lists/${customer?.kit_list?.id}`)
                      }
                      className="inter-base-regular text-blue-700 underline mb-1"
                    >
                      {customer.kit_list?.name}
                    </button>
                  </div>
                  <div className="inter-base-semibold regular mb-1">
                    Last Replenishment:
                    <span className="inter-base-regular mb-1">
                      {" " +
                        moment(activeAllocation?.lastReplenishment).format(
                          "DD MMM YYYY"
                        )}
                    </span>
                  </div>
                  <div className="inter-base-semibold regular mb-1">
                    Next Replenishment:
                    <span className="inter-base-regular mb-1">
                      {" " +
                        moment(activeAllocation?.nextReplenishment).format(
                          "DD MMM YYYY"
                        )}
                    </span>
                  </div>
                </div>
                <ChildGrid
                  entities={activeAllocation?.items?.map((m) => {
                    return {
                      quantity: m.quantity,
                      kit_list_item: m.kit_list_item,
                      kit_list_item_id: m.kit_list_item.id,
                    }
                  })}
                  edit={false}
                  columns={[
                    {
                      header: "Remaining Quantity",
                      field: "quantity",
                      readOnly: true,
                    },
                    {
                      header: "Kit List Item",
                      field: "kit_list_item",
                      formatter: (item) => {
                        return item?.name
                      },
                      readOnly: true,
                    },
                  ]}
                  editEntityActions={undefined}
                  handleChange={undefined}
                  linkRows={true}
                  childrenDetailsLink={
                    "kit-lists/" + customer?.kit_list?.id + "/kitListItem"
                  }
                  detailsId="kit_list_item_id"
                >
                  {}
                </ChildGrid>
              </div>
            )}
          </div>
        )}
      </BodyCard>

      <BodyCard
        className={"h-auto w-full mb-4"}
        title={"Kit-list"}
        subtitle="The customer's Kit-list"
      >
        {isLoadingCustomer || !customer ? (
          <div className="w-full pt-2xlarge flex items-center justify-center">
            <Spinner size={"large"} variant={"secondary"} />
          </div>
        ) : (
          <div>
            {customer.kit_list && (
              <div>
                <div className="border p-4 rounded-rounded mb-4">
                  <h3 className="inter-base-semibold regular mb-1">
                    Current Kit-list
                  </h3>
                  <div className="grid grid-cols-3">
                    <div>
                      Allocated kitList:{" "}
                      <button
                        onClick={() =>
                          navigate(`/a/kit-lists/${customer?.kit_list?.id}`)
                        }
                        className="inter-base-regular text-blue-700 underline mb-1"
                      >
                        {customer.kit_list?.name}
                      </button>
                    </div>
                    <div>
                      Last Replenishment:
                      <span className="inter-base-regular mb-1">
                        {" " +
                          moment(activeAllocation?.lastReplenishment).format(
                            "DD MMM YYYY"
                          )}
                      </span>
                    </div>
                    <div>
                      Next Replenishment:
                      <span className="inter-base-regular mb-1">
                        {" " +
                          moment(activeAllocation?.nextReplenishment).format(
                            "DD MMM YYYY"
                          )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </BodyCard>

      <div className="mt-large">
        <RawJSON data={customer} title="Raw customer" />
      </div>

      {showEdit && (
        <EditCustomerModal
          customer={customer}
          handleClose={() => setShowEdit(false)}
        />
      )}
    </div>
  )
}

export default CustomerDetail
