import React from "react"
import { formatDatetimeForInput } from "../../../utils/time"

interface EditAllocationUpdatedAtFieldProps {
  updatedAt: Date
}

const EditAllocationUpdatedAtField: React.FC<EditAllocationUpdatedAtFieldProps> = ({
  updatedAt,
}) => (
  <div className="text-gray-400 items-start bg-grey-5 p-3 flex flex-col border border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
    <label htmlFor="updated-at">Updated at:</label>
    <input
      type="datetime-local"
      id="updated-at"
      name="updated-at"
      value={formatDatetimeForInput(updatedAt.toString())}
      disabled
    />
  </div>
)

export default EditAllocationUpdatedAtField
