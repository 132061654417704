import React from "react"

interface EditAllocationActiveFieldProps {
  disabled: boolean
  active: boolean
  handleChangeActive: () => void
}

const EditAllocationActiveField: React.FC<EditAllocationActiveFieldProps> = ({
  active,
  disabled,
  handleChangeActive,
}) => (
  <div className="items-start bg-grey-5 p-3 flex flex-col border border-grey-20 focus-within:shadow-input focus-within:border-violet-60 rounded-rounded">
    <label htmlFor="active">Active:</label>
    <input
      disabled={disabled}
      type="checkbox"
      id="active"
      name="active"
      checked={active}
      onChange={handleChangeActive}
    />
  </div>
)

export default EditAllocationActiveField
