import React from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { trimValues } from "../../../../utils/trim-values"

const defaultKitList = {
  replenishmentFrequencyValue: 0,
  ReplenishmentFrequencyMetric: "MONTHS",
  name: null,
  items: [],
  customers: [],
  allocations: []
}

const KitListFormContext = React.createContext<{
  onSubmit: (values: any) => void
  resetForm: () => void
  customers: any[],
  setCustomers: (customer: any[]) => void,
  items: any[],
  setItems: (customer: any[]) => void,
  allocations: any[],
  setAllocations: (customer: any[]) => void,
} | null>(null)

export const KitListFormProvider = ({
  kitList = defaultKitList,
  onSubmit,
  children,
}) => {
  const methods = useForm()
  const [customers, setCustomers] = React.useState<any[]>([]);
  const [items, setItems] = React.useState<any[]>(kitList.items);
  const [allocations, setAllocations] = React.useState<any[]>([]);
  const resetForm = () => {
    methods.reset({
      ...kitList,
    })
  }

  React.useEffect(() => {
    resetForm()
  }, [kitList])

  const handleSubmit = (values) => {
    values['items'] = items;
    onSubmit(
      { ...trimValues(values) }
    )
  }

  return (
    <FormProvider {...methods}>
      <KitListFormContext.Provider
        value={{
          onSubmit: handleSubmit,
          resetForm,
          customers,
          setCustomers,
          items,
          setItems,
          allocations,
          setAllocations
        }}
      >
        {children}
      </KitListFormContext.Provider>
    </FormProvider>
  )
}

export const useKitListForm = () => {
  const context = React.useContext(KitListFormContext)
  const form = useFormContext()
  if (!context) {
    throw new Error("useKitListForm must be a child of KitListFormContext")
  }
  return { ...form, ...context }
}
