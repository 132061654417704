import React, { useEffect } from "react"
import Spinner from "../../components/atoms/spinner"
import ChildGrid from "../../components/base/child-grid"
import BodyCard from "../../components/organisms/body-card"
import { useGridColumns } from "../../components/variant-grid/use-grid-columns"
import { useAdminKitListItem } from "../../hooks/use-admin-kitList"

const KitListitemPage = ({ id, itemId }) => {
  let kit_list_item = {} as any
  if (itemId != null) {
    kit_list_item = useAdminKitListItem(id, itemId) as any
  }

  const columns = useGridColumns(null, false)

  return (
    <div>
      <BodyCard
        forceDropdown
        title={kit_list_item?.name}
        subtitle={`Quantity: ${kit_list_item?.quantity}`}
      >
        <ChildGrid
          entities={kit_list_item?.variants ?? []}
          edit={false}
          columns={columns}
          editEntityActions={undefined}
          handleChange={undefined}
          linkRows={false}
          childrenDetailsLink={""}
        ></ChildGrid>
      </BodyCard>
    </div>
  )
}
export default KitListitemPage
