import React, { createContext, PropsWithChildren, useState } from "react"
import {
  useAdminCreateCustomer,
  useAdminUpdateCustomer,
} from "@iq/medusa-react"
import useNotification from "../../../../hooks/use-notification"
import { Customer } from "../../../../../static/types/medusa-types"
import CustomerModal from "../customer-modal"

// import CustomerGroupModal from "../customer-group-modal"
// import { getErrorMessage } from "../../../../utils/error-messages"
// import useNotification from "../../../../hooks/use-notification"

type CustomerContextT = {
  customer?: Customer
  showModal: () => void
  hideModal: () => void
}

const CustomerContext = createContext<CustomerContextT>()

type CustomerContextContainerT = PropsWithChildren<{
  customer?: Customer
}>

/*
 * A context provider which sets a display mode for `CustomerGroupModal` (create/edit)
 * and provide form data inside the context.
 */
export function CustomerContextContainer(props: CustomerContextContainerT) {
  const notification = useNotification()

  const { mutate: updateCustomer } = useAdminUpdateCustomer(props?.customer)
  const { mutate: createCustomer } = useAdminCreateCustomer(props?.customer)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const hideModal = () => setIsModalVisible(false)

  const handleSubmit = (data) => {
    const isEdit = !!props.customer
    const method = isEdit ? updateCustomer : createCustomer

    const message = `Successfully ${
      isEdit ? "edited" : "created"
    } the customer `

    method(data, {
      onSuccess: () => {
        notification("Success", message, "success")
        hideModal()
      },
      onError: (error: any) =>
        notification(
          "Error",
          error?.response?.data?.error?.message?.message?.message ??
            JSON.stringify(error?.response?.data),
          "error"
        ),
    })
  }

  const context = {
    customer: props.customer,
    isModalVisible,
    showModal,
    hideModal,
  }

  return (
    <CustomerContext.Provider value={context}>
      {props.children}
      {isModalVisible && (
        <CustomerModal
          handleClose={hideModal}
          handleSubmit={handleSubmit}
          initialData={props.customer}
        />
      )}
    </CustomerContext.Provider>
  )
}

export default CustomerContext
