import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import { medusaClient } from "../../../medusa-client"
import { useQuery } from "react-query"
import Spinner from "../../../components/atoms/spinner"
import EditAllocationForm from "./edit-allocation-form"
import { Allocation } from "../../../../static/types/medusa-types"
export interface AllocationData {
  allocation: Allocation
}

const AllocationDetails: React.FC<RouteComponentProps> = () => {
  // Get the allocation id from the url
  const splitPathname = window.location.pathname.split("/")
  const allocationId = splitPathname[splitPathname.length - 1]

  // Redirect to the allocations table view if no allocaiton id is found
  if (!allocationId) {
    navigate("/allocations")
    return <Spinner />
  }

  // Get the allocation from api
  const fetchAllocation = async (id: string) =>
    medusaClient.admin.client.request("GET", `/admin/allocations/${id}`)

  const { data, isLoading, refetch } = useQuery<AllocationData>(
    ["fetch_allocation"],
    () => fetchAllocation(allocationId),
    {
      keepPreviousData: false,
    }
  )

  // Show spinner whilst getting data
  if (isLoading) {
    return <Spinner />
  }

  // Let the user know if no results are found
  if (!data?.allocation) {
    return <p>No result found</p>
  }

  const { allocation } = data

  // Once allocation data loads, display it to the user
  return (
    <>
      <div className="flex flex-col h-full">
        <Breadcrumb
          currentPage="Edit Allocation"
          previousBreadcrumb="Allocations"
          previousRoute="/a/allocations"
        />
        <div className="rounded-rounded py-large px-xlarge border border-grey-20 bg-grey-0 mb-large">
          <EditAllocationForm allocation={allocation} refetch={refetch} />
        </div>
      </div>
    </>
  )
}

export default AllocationDetails
