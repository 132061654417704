import React from "react"
import { Allocation_item } from "../../../../static/types/medusa-types"

interface EditAllocationItemQuantityFieldProps {
  handleChangeQuantity: (itemId: number, quantity: number) => void
  item: Allocation_item
}

const EditAllocationItemQuantityField: React.FC<EditAllocationItemQuantityFieldProps> = ({
  handleChangeQuantity,
  item,
}) => {
  const { id, quantity } = item

  const onChangeQuantity: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    handleChangeQuantity(id, parseInt(event.target.value))
  }

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="items-start bg-grey-5 p-3 flex flex-col border border-grey-20 rounded-rounded">
        <label htmlFor={`item-quantity-${id}`}>Quantity:</label>
        <input
          className="border rounded-md p-1 px-2"
          type="number"
          id={`item-quantity-${id}`}
          name={`item-quantity-${id}`}
          value={quantity}
          onChange={onChangeQuantity}
        />
      </div>
      <div className="items-start bg-grey-5 p-3 flex flex-col border border-grey-20 rounded-rounded">
        <label htmlFor={`item-quantity-${id}`}>Max Allocated Quantity:</label>
        <span className="p-1">
          {item?.kit_list_item?.quantity}
        </span>
      </div>
    </div>
  )
}

export default EditAllocationItemQuantityField
