import React from "react"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import { CustomerPermissionsContextContainer } from "./context/customer-permissions-context"
import { useMutation, useQuery } from "react-query"
import { medusaClient } from "../../../medusa-client"
import { CustomerPermissions } from "../../../../static/types/medusa-types"
import { Controller, useForm } from "react-hook-form"
import { useAdminCustomers } from "medusa-react"
import Checkbox from "../../../components/atoms/checkbox"
import Input from "../../../components/molecules/input"
import Button from "../../../components/fundamentals/button"
import Select from "react-select/async"
import { update } from "lodash"
import XCircleIcon from "../../../components/fundamentals/icons/x-circle-icon"

const primaryColor = "#7c3aed"

const lighten = (amount, color) => {
  // Convert hex to RGB values
  const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16))
  // Apply lighten algorithm
  const lightenChannel = (channel) =>
    Math.round(channel + (255 - channel) * amount)
  const [lightenedR, lightenedG, lightenedB] = [r, g, b].map(lightenChannel)
  // Convert RGB back to hex
  const lightenColor = `#${((lightenedR << 16) | (lightenedG << 8) | lightenedB)
    .toString(16)
    .padStart(6, "0")}`
  return lightenColor
}

type CustomerPermissionsDetailsProps = { id: string }
export default function Details(p: CustomerPermissionsDetailsProps) {
  if (!p.id) return null

  const response = useQuery(["customer-permission", p.id], () => {
    return medusaClient.admin.client.request(
      "GET",
      `/admin/customer-permissions/${p.id}`
    )
  })

  const isLoading = response.isLoading
  const customerPermission = response?.data?.customerPermission || null

  const { mutateAsync: updatePermission, isLoading: isUpdating } = useMutation(
    ["customer-permission-update"],
    (data: Partial<CustomerPermissions>) => {
      return medusaClient.admin.client.request(
        "POST",
        `/admin/customer-permissions/${p.id}`,
        data
      )
    }
  )

  return (
    <CustomerPermissionsContextContainer>
      <div className="-mt-4 pb-4">
        <Breadcrumb
          currentPage={customerPermission?.name || ""}
          previousBreadcrumb="Permissions"
          previousRoute="/a/customers/permissions"
        />
        <CustomerPermissionForm
          permission={customerPermission}
          onSubmit={async (data) => {
            updatePermission(data)
            response.refetch()
          }}
        />
      </div>
    </CustomerPermissionsContextContainer>
  )
}

function SelectGroup(props: {
  placeholder: string
  name: string
  label: string
  selectedOptions: Array<{ label: string; value: string }>
  setSelectedOptions: (value: any) => void
  options: Array<{ label: string; value: string }>
  loadOptions: (
    inputValue: string
  ) => Promise<Array<{ label: string; value: string }>>
}) {
  const [showSelected, setShowSelected] = React.useState(true)
  return (
    <div className="space-y-4 pt-4">
      <span className="inter-base-semibold">{props.label}</span>
      <div>
        <Select
          isMulti
          defaultOptions={true}
          value={props.selectedOptions}
          name={props.name}
          controlShouldRenderValue={false}
          isClearable
          hideSelectedOptions={!showSelected}
          placeholder={props.placeholder || "Select..."}
          options={props.options}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            // ClearIndicator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: primaryColor,
              primary25: lighten(0.25, primaryColor),
              primary50: lighten(0.5, primaryColor),
              primary75: lighten(0.75, primaryColor),
            },
          })}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
            }),
          }}
          menuPlacement="auto"
          maxMenuHeight={200}
          loadOptions={props.loadOptions}
          onChange={(value) => {
            props.setSelectedOptions(value as any)
          }}
        />

        <div className="flex space-x-2 pb-2">
          <button onClick={() => setShowSelected(!showSelected)}>
            <span className="text-xs text-gray-600">
              {showSelected ? "Hide selected" : "Show selected"}
            </span>
          </button>
        </div>
      </div>
      <div
        className="flex flex-wrap gap-2 bg-gray-100 p-2 rounded"
        style={{
          minHeight: 60,
          maxHeight: 200,
          overflowY: "auto",
        }}
      >
        {props.selectedOptions?.map((c) => {
          return (
            <div className="flex items-center justify-between border p-2 rounded-lg space-x-2 bg-white text-xs">
              <span className="">{c.label}</span>
              <button
                onClick={() => {
                  props.setSelectedOptions(
                    props.selectedOptions.filter((s) => s.value !== c.value)
                  )
                }}
              >
                <XCircleIcon color="#9CA3AF" className="mr-2" />
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export function CustomerPermissionForm({
  permission,
  onSubmit: onSubmitProp,
}: {
  permission?: CustomerPermissions | null
  onSubmit: (data: any) => Promise<any>
}) {
  const isEdit = permission?.id != null

  if (permission == null) return null

  const customerResponse = useQuery(["customers"], () => {
    return medusaClient.admin.customers.list()
  })

  const categoryResponse = useQuery(["category"], () => {
    return medusaClient.admin.client.request("GET", `/admin/categories`)
  })

  const customerOptions =
    customerResponse?.data?.customers?.map((m) => {
      return { label: m.email, value: m.id }
    }) || []

  console.log("Category response :,", categoryResponse)
  const categoryOptions =
    categoryResponse?.data?.categories?.map((m) => {
      return { label: m.name, value: m.id }
    }) || []

  const defaultValues = {
    ...permission,
    CATEGORY_ALLOW_LIST:
      permission?.CATEGORY_ALLOW_LIST?.map((c) => ({
        label: c.name,
        value: `${c.id}`,
      })) || [],
    customers:
      permission?.customers?.map((c) => ({
        label: c.email,
        value: c.id,
      })) || [],
  }

  const [selectedCustomers, setSelectedCustomers] = React.useState<
    { value: string; label: string }[]
  >(
    permission?.customers?.map((c) => ({
      label: c.email,
      value: c.id,
    }))
  )

  const [selectedCategories, setSelectedCategories] = React.useState<
    { value: string; label: string }[]
  >(
    permission?.CATEGORY_ALLOW_LIST?.map((c) => ({
      label: c.name,
      value: `${c.id}`,
    }))
  )

  const { control, register, handleSubmit: handleFromSubmit } = useForm({
    defaultValues,
  })

  const onSubmit = async (data) => {
    data.customers = selectedCustomers.map((c) => c.value)
    data.CATEGORY_ALLOW_LIST = selectedCategories.map((c) => c.value)
    await onSubmitProp(data)
  }

  if (!customerResponse.data) return null

  return (
    <div className="bg-white p-4 flex justify-center rounded h-full ">
      <div className="w-full max-w-7xl py-4">
        <div className="space-y-4">
          <span className="inter-base-semibold">Details</span>
          <div className="flex space-x-4">
            <Input
              label="Title"
              name="name"
              defaultValue={defaultValues.name}
              placeholder="Permission name"
              required
              ref={register}
            />
          </div>
        </div>
        <div className="space-y-4 pt-4">
          <span className="inter-base-semibold">Permissions</span>
          <div className="flex flex-col space-y-4">
            <Checkbox
              label="Allow bypass collections scope"
              name="ALLOW_BYPASS_COLLECTIONS_SCOPE"
              defaultChecked={defaultValues.ALLOW_BYPASS_COLLECTIONS_SCOPE}
              ref={register}
            />
            <Checkbox
              label="Allow multiple branches on checkout"
              name="ALLOW_MULTI_BRANCH"
              defaultChecked={defaultValues.ALLOW_MULTI_BRANCH}
              ref={register}
            />
            <Checkbox
              label="Allow bypass allocation allowance"
              name="ALLOW_BYPASS_ALLOCATION_LIMIT"
              defaultChecked={defaultValues.ALLOW_BYPASS_ALLOCATION_LIMIT}
              ref={register}
            />
            <Checkbox
              label="Allow bypass allocation visibility"
              name="ALLOW_BYPASS_ALLOCATION_SCOPE"
              defaultChecked={defaultValues.ALLOW_BYPASS_ALLOCATION_SCOPE}
              ref={register}
            />

            <SelectGroup
              setSelectedOptions={setSelectedCustomers}
              selectedOptions={selectedCustomers}
              options={customerOptions}
              placeholder="Search for a customer"
              name="customers"
              label="Customers"
              loadOptions={async (inputValue) => {
                const response = await medusaClient.admin.customers.list({
                  q: inputValue,
                  limit: 50,
                  offset: 0,
                })

                const allOptions = response.customers.map((c) => ({
                  label: c.email,
                  value: c.id,
                }))

                return allOptions
                // return selectedCustomers.concat(
                //   allOptions.filter((o) => {
                //     return !selectedCustomers.find((s) => s.value === o.value)
                //   })
                // )
              }}
            />

            <SelectGroup
              setSelectedOptions={setSelectedCategories}
              selectedOptions={selectedCategories}
              options={categoryOptions}
              placeholder="Search for a category"
              name="categories"
              label="Categories"
              loadOptions={async (inputValue) => {
                const allOptions = categoryOptions
                return allOptions
              }}
            />
          </div>
        </div>

        <div className="mt-8">
          <Button
            size="medium"
            variant="primary"
            onClick={handleFromSubmit(onSubmit)}
            className="rounded-rounded"
          >
            Save Permission
          </Button>
        </div>
      </div>
    </div>
  )
}
