import { useLocation } from "@reach/router"
import { useAdminProducts } from "@iq/medusa-react"
import React, { useState } from "react"
import ProductsFilter from "../../../domain/products/filter-dropdown"
import ProductOverview from "./overview"
import useProductActions from "./use-product-actions"
import useProductTableColumn from "./use-product-column"
import { useProductFilters } from "./use-product-filters"
import EntityTable from "../entity-table"

const DEFAULT_PAGE_SIZE = 15
const DEFAULT_PAGE_SIZE_TILE_VIEW = 18

type ProductTableProps = {}

const defaultQueryProps = {
  expand: "variants,options,variants.prices,variants.options,collection,tags",
  is_giftcard: false,
}

const ProductTable: React.FC<ProductTableProps> = () => {
  const location = useLocation()

  const {
    removeTab,
    setTab,
    saveTab,
    availableTabs: filterTabs,
    activeFilterTab,
    reset,
    setFilters,
    setLimit,
    filters,
    queryObject,
  } = useProductFilters(location.search, defaultQueryProps)

  const [query, setQuery] = useState(queryObject.query)

  const clearFilters = () => {
    reset()
    setQuery("")
  }

  const setListView = () => {
    setLimit(DEFAULT_PAGE_SIZE)
    setShowList(true)
  }

  const setTileView = () => {
    setLimit(DEFAULT_PAGE_SIZE_TILE_VIEW)
    setShowList(false)
  }

  const [showList, setShowList] = React.useState(true)

  const [offset, setOffset] = useState<number>(0)

  const { products, isLoading, isRefetching, count, refetch } =
    useAdminProducts({
      ...queryObject,
      offset,
      q: query,
    })

  const [columns] = useProductTableColumn({
    setTileView,
    setListView,
    showList,
  })

  return (
    <EntityTable
      defaultQueryProps={defaultQueryProps}
      useEntityFilters={useProductFilters}
      useEntityActions={useProductActions}
      entities={products}
      isLoading={isLoading}
      isRefetching={isRefetching}
      count={count}
      name="products"
      showList={showList}
      columns={columns}
      query={query}
      setQuery={setQuery}
      setOffset={setOffset}
      refetch={refetch}
    >
      {{
        filters: (
          <ProductsFilter
            filters={filters}
            submitFilters={setFilters}
            clearFilters={clearFilters}
            tabs={filterTabs}
            onTabClick={setTab}
            activeTab={activeFilterTab}
            onRemoveTab={removeTab}
            onSaveTab={saveTab}
          />
        ),
        overview: (
          <ProductOverview products={products} toggleListView={setListView} />
        ),
      }}
    </EntityTable>
  )
}

export default ProductTable
