import useMedusa from "./use-medusa"

export const useAdminSuppliers = (
    query?: any,
) => {
    const data = useMedusa('suppliers', query)
    return { ...data } as const
}

export const useAdminSupplier = (
    id: string
) => {
    const data = useMedusa('suppliers', { id })
    return { ...data, } as const
}