import React from "react"
import { Controller, useForm } from "react-hook-form"
import Checkbox from "../../../components/atoms/checkbox"
import Button from "../../../components/fundamentals/button"
import Input from "../../../components/molecules/input"
import Modal from "../../../components/molecules/modal"
import {
  Category,
  Customer,
  CustomerPermissions,
} from "../../../../static/types/medusa-types"
import CustomerResults from "../../../components/templates/search-modal/results/customer-results"
import { useAdminCustomers } from "@iq/medusa-react"
import { medusaClient } from "../../../medusa-client"
import Select from "react-select/async"
import { CustomerPermissionForm } from "./details"

interface CustomerPermissionsModalProps {
  initialData?: CustomerPermissions
  handleClose: () => void
  handleSubmit: (data: CustomerPermissions) => Promise<any>
}
export default function CustomerPermissionsModal({
  handleSubmit,
  handleClose,
  initialData,
}: CustomerPermissionsModalProps) {
  const {
    control,
    register,
    handleSubmit: handleFromSubmit,
  } = useForm({
    defaultValues: initialData,
  })

  const customerOptions =
    useAdminCustomers().customers?.map((m) => {
      return { label: m.email, value: m.id }
    }) || []

  const onSubmit = (data) => {
    handleSubmit(data)
  }

  return (
    <Modal handleClose={handleClose}>
      <Modal.Body isLargeModal>
        <Modal.Header handleClose={handleClose}>
          <span className="inter-xlarge-semibold">
            {initialData ? "Edit" : "Create a New"} Customer Permission
          </span>
        </Modal.Header>

        <Modal.Content isLargeModal>
          <CustomerPermissionForm
            onSubmit={handleSubmit}
            permission={{
              name: "", // i.e admin, remote worker, standard customer
              CATEGORY_ALLOW_LIST: [],
              ALLOW_BYPASS_COLLECTIONS_SCOPE: false,
              ALLOW_BYPASS_ALLOCATION_SCOPE: false, // allows the customer to see isAllocation variants
              ALLOW_BYPASS_ALLOCATION_LIMIT: false, // allows the customer to exceed their allocation limit
              ALLOW_PERSONAL_ADDRESS: false, // remote workers - shows extra step at checkout to input this adress
              ALLOW_MULTI_BRANCH: false, // when user checks out they can see all branches
              customers: [],
            }}
          />
        </Modal.Content>
      </Modal.Body>
    </Modal>
  )
}
