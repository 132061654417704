import React, { useState } from "react"
import useImperativeDialog from "../../hooks/use-imperative-dialog"
import useNotification from "../../hooks/use-notification"
import ChildGrid from "../base/child-grid"
import EditIcon from "../fundamentals/icons/edit-icon"
import TrashIcon from "../fundamentals/icons/trash-icon"
import {
  useAdminRemoveKitListItem,
  useAdminUpdateKitListItem,
} from "@iq/medusa-react"
import ItemEditor from "../../domain/kit-lists/kit-list-form/details/items/item-editor"
import { getErrorMessage } from "../../utils/error-messages"
import { kitListItemMapper } from "../../domain/kit-lists/kit-list-form/form/mappers"
import { medusaClient } from "../../medusa-client"
import RefreshIcon from "../fundamentals/icons/refresh-icon"
const KitListGrid = ({ kitList, setItems, items, edit, onItemChange }) => {
  const columns = [
    { header: "Name", field: "name" },
    { header: "Quantity", field: "quantity" },
    {
      header: "Variants",
      field: "variants",
      formatter: (e) => e.length,
      readOnly: true,
    },
  ]
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const notification = useNotification()
  const dialog = useImperativeDialog()
  const deleteItem = useAdminRemoveKitListItem(kitList?.id)
  const updateItem = useAdminUpdateKitListItem(kitList?.id)

  const handleChange = (index, field, value) => {
    const newEntities = [...items]
    newEntities[index] = {
      ...newEntities[index],
      [field]: value,
    }

    onItemChange(newEntities)
  }

  const getDisplayValue = (item, column) => {
    const { formatter, field } = column

    return formatter ? formatter(item[field]) : item[field]
  }

  const handleUpdateItem = (data) => {
    updateItem.mutate(
      {
        itemId: selectedItem.id,
        variant_ids: data.variants.map((v) => v.id),
        quantity: Number.parseInt(data.quantity),
        name: data.name,
      },
      {
        onSuccess: (kit_list) => {
          console.log("response ->", kit_list)
          setItems(kitListItemMapper(kit_list.items))
          notification(
            "Success",
            `Successfully update kit list item`,
            "success"
          )
          setSelectedItem(null)
        },
        onError: (err) => {
          console.log("err->", err)
          notification("Error", getErrorMessage(err), "error")
        },
      }
    )
  }

  const handleDeleteItem = async (item) => {
    const shouldDelete = await dialog({
      heading: `Delete kit list item`,
      text: "Are you sure?",
    })

    if (shouldDelete) {
      return deleteItem.mutate(item.id, {
        onSuccess: () => {
          setItems(items.filter((f) => f.id != item.id))
          notification("Success", "item successfully deleted", "success")
        },
        onError: (error) => {
          notification("Error", getErrorMessage(error), "error")
        },
      })
    }
  }

  const replenishKitListItem = async function (kitlist) {
    const shouldReplenish = await dialog({
      heading: "Force Replenish Kit List ",
      text: "Are you sure you want to replenish this Kit List Item",
    })

    if (!shouldReplenish) {
      return null
    }

    const response = await medusaClient.admin.client.request(
      "POST",
      `/admin/kit_list_items/replenish/${kitlist.id}`
    )

    return response
  }

  const editItemActions = (entity) => {
    return [
      {
        label: "Force Replenish",
        onClick: () => replenishKitListItem(kitList),
        icon: <RefreshIcon size={20} />,
      },
      {
        label: "Edit",
        icon: <EditIcon size={20} />,
        onClick: () => setSelectedItem(entity),
      },
      {
        label: "Delete",
        icon: <TrashIcon size={20} />,
        onClick: () => handleDeleteItem(entity),
        variant: "danger",
      },
    ]
  }

  return (
    <>
      <ChildGrid
        entities={items}
        edit={edit}
        columns={columns}
        editEntityActions={editItemActions}
        getDisplayValue={getDisplayValue}
        handleChange={handleChange}
        linkRows={true}
        childrenDetailsLink={"kit-lists/" + kitList?.id + "/kitListItem"}
      >
        {selectedItem && (
          <ItemEditor
            item={selectedItem}
            onCancel={() => setSelectedItem(null)}
            onSubmit={handleUpdateItem}
            title={
              selectedItem?.name
                ? `Edit Kit List Item: ${selectedItem?.name}`
                : "Edit Kit List Item"
            }
          />
        )}
      </ChildGrid>
    </>
  )
}

export default KitListGrid
