import React, { useMemo } from "react"

const useApprovalGroupTableColumn = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Approvers",
        accessor: "approvers",
        Cell: ({ cell: { value } }) => <div>{value?.length || 0}</div>,
      },
      {
        Header: "Customers",
        accessor: "customers",
        Cell: ({ cell: { value } }) => <div>{value?.length || 0}</div>,
      },
      {
        Header: "Orders",
        accessor: "order_approvals",
        Cell: ({ cell: { value } }) => <div>{value?.length || 0}</div>,
      },
    ],
    []
  )

  return [columns]
}

export default useApprovalGroupTableColumn
