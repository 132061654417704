
import React from "react"

interface EditAllocationReplenishButtonProps {
  onClick: () => Promise<any>
  disabled?: boolean;
}

const EditAllocationReplenishButton: React.FC<EditAllocationReplenishButtonProps> = ({
  onClick,
  disabled = false
}) => (
  <input
    className={`btn-primary ${disabled ? "cursor-not-allowed" : "cursor-pointer"
      } px-4 py-2 rounded-rounded self-start`}
    type="button"
    value="Replenish"
    onClick={onClick}
    disabled={disabled}
  />
)

export default EditAllocationReplenishButton
