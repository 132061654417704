import React from "react"
import GridInput from "../molecules/grid-input"
import Table from "../molecules/table"

const ChildGrid = ({
  entities,
  edit,
  columns,
  editEntityActions,
  handleChange,
  children,
  linkRows = false,
  childrenDetailsLink = "",
  detailsId = "id",
}) => {
  const getDisplayValue = (entity, column) => {
    const { formatter, field } = column
    return formatter ? formatter(entity[field]) : entity[field]
  }
  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadRow>
            {columns.map((col, index) => (
              <Table.HeadCell className="w-[100px] px-2 py-4" key={index}>
                {col.header}
              </Table.HeadCell>
            ))}
          </Table.HeadRow>
        </Table.Head>
        <Table.Body>
          {entities.map((entity, i) => {
            return (
              <Table.Row
                key={i}
                linkTo={
                  linkRows
                    ? `/a/${childrenDetailsLink}/${entity[detailsId]}`
                    : ""
                }
                color={"inherit"}
                actions={edit && editEntityActions(entity)}
              >
                {columns.map((col, j) => {
                  return (
                    <Table.Cell key={j}>
                      {edit || col.readOnly ? (
                        <div className="px-2 py-4 truncate">
                          {getDisplayValue(entity, col) || "Untitled"}
                        </div>
                      ) : (
                        <GridInput
                          key={j}
                          value={entity[col.field]}
                          required={col?.required ?? false}
                          onChange={({ currentTarget }) =>
                            handleChange(i, col.field, currentTarget.value)
                          }
                        />
                      )}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {children}
    </>
  )
}

export default ChildGrid
